export const FETCH_USER_SUBSCRIPTION_DATA = 'FETCH_USER_SUBSCRIPTION_DATA';
export const UPDATE_USER_SUBSCRIPTION_DATA = 'UPDATE_USER_SUBSCRIPTION_DATA';

export const fetchUserSubscriptionData = (userData) => ({
    type: FETCH_USER_SUBSCRIPTION_DATA,
    payload: userData,
});

export const updateUserSubscriptionData = (userData) =>({
    type: UPDATE_USER_SUBSCRIPTION_DATA,
    payload: userData
})