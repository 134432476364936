import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function CreateCustomerPortalSession(return_url) {

    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };

    var raw = JSON.stringify({
        session_id: Token,
        return_url: return_url
    });

    var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
        headers: header,
    };
    
    if (!CheckSessionTimeout(Token)){
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_CREATE_CUSTOMER_PORTAL_API,
                requestOptions
            );
            if (!response.ok) {
                console.log("Could not generate user session");
                return false;
            } else {
                const message = await response.json();
                if (message["result"] !== "") {
                    return message["result"];
                } else {
                    console.log(message["error"]);
                    return false
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                return 0
            }
            else {
                return 0
            }
        }
    }
    else{
        return 401
    }
}
