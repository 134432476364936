import {
    FETCH_USER_SUBSCRIPTION_DATA,
    UPDATE_USER_SUBSCRIPTION_DATA,
} from '../actions/userSubscriptionActions';

const initialState = {
    subscriptionData: {},
    isLoading: false,
    error: null,
};

const userSubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USER_SUBSCRIPTION_DATA':
            return { ...state,
                subscriptionData: action.payload, 
                error: null,
                isLoading: false
            };
        case 'UPDATE_USER_SUBSCRIPTION_DATA':
            return {
                ...state,
                subscriptionData: {
                    ...state.subscriptionData,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default userSubscriptionReducer;
