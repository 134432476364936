import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "../../actions/drawerActions";
import { portfolioAdded } from "../../actions/portfolioActions";
import "./AddClientPortfolioLineItems.scss";
import { ClientDepthManualAddApi } from "../../Apis/ClientDepthManualAdd";
import { handleApiError, showNotification } from "../../actions/notificationActions";
import Toaster from "../../Components/Toaster/Toaster";
import TagManager from "react-gtm-module";
import AutoCompleteTicker from "../../Components/AutoCompleteTicker/AutoCompleteTicker";

function ClientPortfolioLineItemsManualImport({ onSubmitRedirectRoute }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [clientName, setClientName] = useState("Client 001");
  const [externalId, setExternalId] = useState("");
  const [clientPortfolios, setClientPortfolios] = useState([
    {
      portfolio_name: "Portfolio 001",
      account_id: "",
      line_items: [],
    },
  ]);
  const [clientPortfolioErrors, setClientPortfolioErrors] = useState([
    {
      portfolio_name: "",
      line_items: [],
    },
  ]);
  const [clientNameError, setClientNameError] = useState("");
  const allAvailableTickers = useSelector(
    (state) => state.availableTickers.availableTickers
  );
  const { customers } = useSelector(state => state.portfolio);
  const totalPortfolioCount = customers.reduce((total, customer) => {
    return total + (Array.isArray(customer.Portfolio) ? customer.Portfolio.length : 0);
  }, 0);
  const subscriptionsDetails = useSelector(state => state.userSubscription.subscriptionData) || {};

  const checkAddPortfolioLineItems = (portfolioLineItemsCount, action) => {
    const limit = subscriptionsDetails.subscription_plan_active_portfolio_line_items_limit;
    if (limit === 'Infinity') {
        return true;
    }
    const exceedsLimit = action === 'submit' 
        ? portfolioLineItemsCount > limit
        : portfolioLineItemsCount >= limit;

    if (exceedsLimit) {
        const message = `Your current portfolio line items count (${portfolioLineItemsCount}) exceeds the limit of ${limit}. Please upgrade your subscription to add more.`;
        dispatch(showNotification(message, "info", "toaster"));
        return false;
    }
    return true;
  };

  const handleAddLineItem = (portfolioIndex) => {
    const validPliCount = clientPortfolioErrors[portfolioIndex]?.line_items?.filter(error =>
      error.symbol === '' || (error.symbol && error.symbol !== "Ticker Not Recognized")
    ).length || 0;
    if(checkAddPortfolioLineItems(validPliCount,'add')){
      const updatedPortfolioLineItems = [...clientPortfolios];
      updatedPortfolioLineItems[portfolioIndex].line_items.push({
        symbol: "",
        quantity: "",
      });
      setClientPortfolios([...updatedPortfolioLineItems]);
      const portfolioLineItemsErrors = [...clientPortfolioErrors];
      portfolioLineItemsErrors[portfolioIndex].line_items.push({
        symbol: "",
        quantity: "",
      });
      setClientPortfolioErrors([...portfolioLineItemsErrors]);
    }
  };

  const handleDeleteLineItem = (portfolioIndex, lineItemIndex) => {
    const deletedPortfolioLineItems = [...clientPortfolios];
    deletedPortfolioLineItems[portfolioIndex].line_items.splice(
      lineItemIndex,
      1
    );
    setClientPortfolios([...deletedPortfolioLineItems]);
    const deletedPortfolioLineItemsErrors = [...clientPortfolioErrors];
    deletedPortfolioLineItemsErrors[portfolioIndex].line_items.splice(
      lineItemIndex,
      1
    );
    setClientPortfolioErrors([...deletedPortfolioLineItemsErrors]);
  };

  const checkAddPortfolioLimit = () => {
    const limit = subscriptionsDetails.subscription_plan_active_portfolio_limit;
    if (limit === 'Infinity') {
        return true;
    }
    const currentPortfolioCount = totalPortfolioCount + clientPortfolios.length;
    if (currentPortfolioCount >= limit) {
        const message = `Your current portfolio count (${currentPortfolioCount}) exceeds the limit of ${limit}. Please upgrade your subscription to add more.`;
        dispatch(showNotification(message, "info", "toaster"));
        return false;
    }
    return true;
  };

  const handleAddPortfolio = () => {
    if (checkAddPortfolioLimit()) {
      const nextPortfolioNumber = clientPortfolios.length + 1;
      const nextPortfolioName = `Portfolio ${String(nextPortfolioNumber).padStart(3, "0")}`;
      setClientPortfolios(prevPortfolios => [
        ...prevPortfolios,
        {
          portfolio_name: nextPortfolioName,
          account_id: "",
          line_items: [],
        },
      ]);
  
      setClientPortfolioErrors(prevErrors => [
        ...prevErrors,
        {
          portfolio_name: "",
          line_items: [],
        },
      ]);
    }
  };
  

  const validateClientName = (clientName) => {
    let error = "";
    let validFlag = true;
    if (!clientName) {
      validFlag = false;
      error = "Client Name cannot be empty.";
    }
    setClientNameError(error);
    return validFlag;
  };

  const handleClientNameChange = (clientName) => {
    validateClientName(clientName);
    setClientName(clientName);
  };

  const validatePortfolioName = (portfolio_name, portfolio_index) => {
    let error = [...clientPortfolioErrors];
    let validFlag = true;
    if (!portfolio_name) {
      validFlag = false;
      error[portfolio_index].portfolio_name = "Please enter portfolio name";
    } else {
      if (
        portfolio_name &&
        !/^[A-Za-z]+[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-\s]*$/.test(
          portfolio_name
        )
      ) {
        validFlag = false;
        error[portfolio_index].portfolio_name = "Invalid Portfolio Name";
      } else {
        error[portfolio_index].portfolio_name = "";
        validFlag = true;
      }
    }
    setClientPortfolioErrors([...error]);
    return validFlag;
  };

  const handlePortfolioNameChange = (e, portfolioIndex) => {
    const portfolio_name = e.target.value.trimLeft();
    validatePortfolioName(portfolio_name, portfolioIndex);
    const updatedPortfolioNames = [...clientPortfolios];
    updatedPortfolioNames[portfolioIndex].portfolio_name = portfolio_name;

    const currentTime = Math.floor(Date.now() / 1000);
    const uniqueId = `${currentTime}_${portfolioIndex}`;
    updatedPortfolioNames[portfolioIndex].account_id = uniqueId;
    setClientPortfolios([...updatedPortfolioNames]);
  };

  const handleDeletePortfolio = (portfolioIndex) => {
    const deletedPortfolios = [...clientPortfolios];
    deletedPortfolios.splice(portfolioIndex, 1);
    setClientPortfolios([...deletedPortfolios]);
    const deletedPortfolioErrors = [...clientPortfolioErrors];
    deletedPortfolioErrors.splice(portfolioIndex, 1);
    setClientPortfolioErrors([...deletedPortfolioErrors]);
  };

  const validateSymbol = (portfolioIndex, lineItemIndex, ticker) => {
    let validFlag = true;
    let error = [...clientPortfolioErrors];
    if (!ticker) {
      error[portfolioIndex].line_items[lineItemIndex].symbol =
        "Please enter ticker";
      validFlag = false;
    } else {
      const referenceTicker = allAvailableTickers.find(
        (refTicker) => refTicker.symbol === ticker
      );
      if (ticker && !/^[A-Za-z\-.$^&]+$/.test(ticker)) {
        error[portfolioIndex].line_items[lineItemIndex].symbol =
          "Invalid Ticker";
        validFlag = false;
      } else if (!referenceTicker) {
        error[portfolioIndex].line_items[lineItemIndex].symbol =
          "Ticker Not Recognized";
        validFlag = true;
      } else if(clientPortfolios[portfolioIndex].line_items.filter(item => item.symbol === ticker).length > 1){
        error[portfolioIndex].line_items[lineItemIndex].symbol =
          "This ticker has already been added";
        validFlag = false;
      } else {
        error[portfolioIndex].line_items[lineItemIndex].symbol = "";
        validFlag = true;
      }
    }
    setClientPortfolioErrors([...error]);
    return validFlag;
  };

  const validateQuantity = (portfolioIndex, lineItemIndex, quantity) => {
    let validFlag = true;
    let error = [...clientPortfolioErrors];
    if (!quantity) {
      error[portfolioIndex].line_items[lineItemIndex].quantity =
        "Please enter quantity";
      validFlag = false;
    } else {
      if (quantity && !/^-?[0-9]\d*\.?\d*$/.test(quantity)) {
        error[portfolioIndex].line_items[lineItemIndex].quantity =
          "Please enter valid quantity";
        validFlag = false;
      } else {
        error[portfolioIndex].line_items[lineItemIndex].quantity = "";
        validFlag = true;
      }
    }
    setClientPortfolioErrors([...error]);
    return validFlag;
  };

  const handleLineItemChange = (
    value,
    portfolioIndex,
    lineItemIndex,
    property
  ) => {
    const updatedPortfolioLineitems = [...clientPortfolios];
    if (property === "symbol") {
      validateSymbol(portfolioIndex, lineItemIndex, value);
      updatedPortfolioLineitems[portfolioIndex].line_items[lineItemIndex][
        "symbol"
      ] = value;
      //updatedPortfolioLineitems[portfolioIndex].lineItems[lineItemIndex]['symbol'] = value.replace(/[^a-zA-Z0-9]/g, '.');
    } else if (property === "quantity") {
      validateQuantity(portfolioIndex, lineItemIndex, value);
      updatedPortfolioLineitems[portfolioIndex].line_items[lineItemIndex][
        "quantity"
      ] = value;
    }
    setClientPortfolios([...updatedPortfolioLineitems]);
  };

  const validateOnSubmit = () => {
    let validFlag = true;
    validFlag = validateClientName(clientName);
    if (clientPortfolios.length !== 0) {
      if(!checkAddPortfolioLimit()){
        validFlag = false;
      }
      clientPortfolios.map((portfolio, portfolio_index) => {
        if (!validatePortfolioName(portfolio.portfolio_name, portfolio_index)) {
          validFlag = false;
        }
        const validPliCount = clientPortfolioErrors[portfolio_index].line_items?.filter(error =>
          error.symbol === '' || (error.symbol && error.symbol !== "Ticker Not Recognized")
        ).length || 0;
        if(!checkAddPortfolioLineItems(validPliCount,'submit')){
          validFlag = false;
        }
        portfolio.line_items.map((lineItem, lineItemIndex) => {
          if (
            !validateSymbol(portfolio_index, lineItemIndex, lineItem.symbol)
          ) {
            validFlag = false;
          }
          if (
            !validateQuantity(portfolio_index, lineItemIndex, lineItem.quantity)
          ) {
            validFlag = false;
          }
        });
      });
    }
    return validFlag;
  };

  const handleOnSubmit = async () => {
    const clientData = {
      full_name: clientName,
      external_id: externalId,
      portfolios: clientPortfolios,
    };
    if (validateOnSubmit()) {
      setIsLoading(true);
      const response = await ClientDepthManualAddApi(clientData);
      if (response.status === 200) {
        dispatch(portfolioAdded());
        dispatch(closeDrawer());
        if (onSubmitRedirectRoute) {
          TagManager.dataLayer({
            dataLayer: {
              event: onSubmitRedirectRoute
                ? "Onboard Client Portfolio"
                : "Client Data Added Manually",
              userType: sessionStorage.getItem("selectedAcType"),
              user_id: sessionStorage.getItem("clientId"),
              onboarding_type: "Manually",
            },
          });
          history.push(onSubmitRedirectRoute);
        }
      } else {
        handleApiError(response, dispatch);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="toast-container">
        <Toaster />
      </div>
      <div className="client-portfolio">
        {isLoading ? (
          <div className="loading-overlay">
            <Loader />
          </div>
        ) : (
          <>
            <form className="ai-row">
              <div className="ai-form-group">
                <label className="ai-form-label">Client Name:</label>
                <input
                  type="text"
                  className="ai-form-value"
                  value={clientName}
                  onChange={(e) => handleClientNameChange(e.target.value)}
                />
                <p className="ai-error-message">{clientNameError}</p>
              </div>
              <div className="ai-form-group">
                <label className="ai-form-label">External ID (Optional):</label>
                <input
                  type="text"
                  className="ai-form-value"
                  value={externalId}
                  onChange={(e) => setExternalId(e.target.value)}
                />
              </div>
            </form>
            <h2 className="ai-title">Portfolios</h2>
            {clientPortfolios.map((portfolio, portfolioIndex) => (
              <div key={portfolioIndex} className="portfoliocard">
                <div className="portfolioheader">
                  <div className="input">
                    <input
                      type="text"
                      className="ai-form-value"
                      placeholder="Portfolio Name"
                      value={portfolio.portfolio_name}
                      onChange={(e) =>
                        handlePortfolioNameChange(e, portfolioIndex)
                      }
                    />
                    <p className="ai-error-message">
                      {clientPortfolioErrors[portfolioIndex].portfolio_name}
                    </p>
                  </div>
                  <button onClick={() => handleDeletePortfolio(portfolioIndex)}>
                    <img src="./Assets/trash.svg" alt="toggle" />
                  </button>
                </div>
                <div className="tickercon">
                  <div className="tickerlist">
                    {portfolio.line_items.length > 0 &&
                      portfolio.line_items.map((lineItem, lineItemIndex) => (
                        <div key={lineItemIndex} className="addticker">
                          <div className="tickerdata">
                            <AutoCompleteTicker
                              onTickerChange={(event, newValue) => {
                                const tickerSymbol = newValue ? newValue.symbol : "";
                                handleLineItemChange(tickerSymbol, portfolioIndex, lineItemIndex, "symbol");
                              }}
                            />
                            <p className="ai-error-message">
                              {
                                clientPortfolioErrors[portfolioIndex]
                                  .line_items[lineItemIndex].symbol
                              }
                            </p>
                          </div>
                          <div className="tickerdata">
                            <input
                              className="ai-form-value"
                              type="text"
                              placeholder="Quantity"
                              value={lineItem.quantity}
                              onChange={(e) =>
                                handleLineItemChange(
                                  e.target.value,
                                  portfolioIndex,
                                  lineItemIndex,
                                  "quantity"
                                )
                              }
                            />
                            <p className="ai-error-message">
                              {
                                clientPortfolioErrors[portfolioIndex]
                                  .line_items[lineItemIndex].quantity
                              }
                            </p>
                          </div>
                          <button
                            onClick={() =>
                              handleDeleteLineItem(
                                portfolioIndex,
                                lineItemIndex
                              )
                            }
                          >
                            <img src="./Assets/trash.svg" alt="toggle" />
                          </button>
                        </div>
                      ))}
                  </div>
                  <span className="addticker">
                    <button onClick={() => handleAddLineItem(portfolioIndex)}>
                      +
                    </button>
                  </span>
                </div>
              </div>
            ))}
            <div className="ai-row">
              <button
                className="ai-btn solid blue"
                onClick={handleAddPortfolio}
              >
                Add More Portfolios
              </button>
              <button
                className="ai-btn solid primary"
                onClick={handleOnSubmit}
                disabled={disableSubmit}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ClientPortfolioLineItemsManualImport;
