import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import "./AdvisorProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { GetStripeSubscriptions } from "../../Apis/GetStripeSubscriptions";
import { CancelStripeSubscription } from "../../Apis/CancelStripeSubscription";
import { ModifyUserSubscription } from "../../Apis/ModifyUserSubscription";
import {
  handleApiError,
  showConfirmNotification,
  showNotification,
} from "../../actions/notificationActions";
import UpdateProfile from "../UpdateAdvisorProfile/UpdateAdvisorProfile";
import Loader from "../../Components/Loader/Loader";
import { openDrawer } from "../../actions/drawerActions";
import { fetchProfile } from "../../actions/advisorProfileActions";
import { updateUserSubscriptionData } from "../../actions/userSubscriptionActions";
import { CreateCustomerPortalSession } from "../../Apis/CreateCustomerPortalSession";
import Toaster from "../Toaster/Toaster";
import TagManager from "react-gtm-module";
import Avatar from "@mui/material/Avatar";

function AdvisorProfile() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [subscriptions, setSubscriptions] = useState([]); 
  const [activePlan, setActivePlan] = useState(null);
  const advisorProfile = useSelector(
    (state) => state.advisorProfile.profileData
  );

  const PaymentLinks = JSON.parse(sessionStorage.getItem("PaymentLinks"));
  const userType = sessionStorage.getItem("userType");
  const redirectToPayment = (plan_name) => {
    const CustomerID = sessionStorage.getItem("CustomerID");
    if (CustomerID && PaymentLinks) {
        if(userType === 'Investor' && plan_name === 'Adaptive Investor Core Plan'){
            const url = new URL(PaymentLinks.investor_wo_trial);
            url.searchParams.set('client_reference_id', CustomerID);
            url.searchParams.set('active_subscription',subscriptions['id']);
            url.searchParams.set('plan_name', plan_name);
            url.searchParams.set('is_update', true);
            window.location.href = url.toString();
        }
        else if(userType === 'Advisor' && plan_name === 'Adaptive Standard Plan'){
            const url = new URL(PaymentLinks.standard_wo_trial);
            url.searchParams.set('client_reference_id', CustomerID);
            url.searchParams.set('active_subscription',subscriptions['id']);
            url.searchParams.set('plan_name', plan_name);
            url.searchParams.set('is_upgrade', true);
            window.location.href = url.toString();
        } else if(userType === 'Advisor' && plan_name === 'Adaptive Advisor Pro Plan'){
            const url = new URL(PaymentLinks.pro_wo_trial);
            url.searchParams.set('client_reference_id', CustomerID);
            url.searchParams.set('active_subscription',subscriptions['id']);
            url.searchParams.set('plan_name', plan_name);
            url.searchParams.set('is_upgrade', true);
            window.location.href = url.toString();
        }
        TagManager.dataLayer({
          dataLayer: {
            event: "Subscription Plan Modified",
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId"),
          },
        });
    } else {
        console.error("CustomerID or PaymentLinks.standard is missing.");
    }
  };

  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleLogOut = () => history.push("/");
  const activeDrawer =
    useSelector((state) => state.drawer.activeDrawer) === "EditProfile";
  const profileImage =
    useSelector(
      (state) => state.userPicture.userPictureData?.profile_picture
    ) || null;
  const subscriptionsDetails =
    useSelector((state) => state.userSubscription.subscriptionData) || {};
  
  const whiteListCustomerFlag = subscriptionsDetails.subscription_plan_type === 'Whitelist Customer Plan';

  const subscriptionPlanNames = [
    { usertype:'Investor', stripe_name: 'Adaptive Investor Core Plan', screen_name: 'Investor', icon:'SELF', price:'$25', price_desc:'Per User/ Per Month', features:['Up to 3 Portfolios of maximum 50 Tickers.','Market Quotes (Prev Close)','User Upload & Update of Portfolio Positions','User Download of Possible Options Trades','No Call Writing Optimization']},
    { usertype:'Advisor', stripe_name: 'Adaptive Standard Plan', screen_name: 'Advisor', icon:'AdvisorPlan' ,price:'$65', price_desc: 'Per User/ Per Month', features:['Up to 10 Portfolios of miximum 100 Tickers.Unlimited if Linked to Brokerage Platforms.','Market Quotes (Prev Close)','User Upload & Update of Portfolio Positions','User Download of Possible Options Trades','Call Writing Optimization During Trial Period']},
    { usertype:'Advisor', stripe_name: 'Adaptive Advisor Pro Plan', screen_name: 'Advisor Pro',icon:'AdvisorPro',price:'$500', price_desc:'', features:['Unlimited Portfolios and Tickers.','Market Quotes (Real Time)','Advisor Platform Integrations for Portfolio Positions','Advisor Platform Integrations for Trade Tickets','Call Writing Optimization']},
  ]

  useEffect(() => {
    setIsLoading(true);
    fetchSubscriptions();
    if (Object.keys(advisorProfile).length === 0) {
      dispatch(fetchProfile()).then(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, advisorProfile]);

  const fetchSubscriptions = () => {
    GetStripeSubscriptions().then((subscriptionsResponse) => {
      setIsLoading(false);
      if (subscriptionsResponse.status === 200) {
        setSubscriptions(subscriptionsResponse.result);
        setActivePlan(
          Object.keys(subscriptionsResponse).length > 0
            ? subscriptionsResponse.result
            : null
        );
        dispatch(updateUserSubscriptionData(subscriptionsResponse.result.stripe_plan_details));
      } else {
        handleApiError(subscriptionsResponse, dispatch);
      }
    });
  };

  const handleCancelSubscription = (subscriptionId) => {
    dispatch(
      showConfirmNotification(
        "Are you sure you want to cancel your subscription plan?",
        () => cancelSubscription(subscriptionId)
      )
    );
  };

  const cancelSubscription = (subscriptionId) => {
    setIsLoading(true);
    CancelStripeSubscription().then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        TagManager.dataLayer({
          dataLayer: {
            event: "Subscription Cancelled",
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId"),
          },
        });
        dispatch(updateUserSubscriptionData(response.result));
        dispatch(
          showNotification(
            "Subscription Cancelled Successfully",
            "success",
            "toaster"
          )
        );
        history.push({ pathname: "/OnboardScreen", state: { stage: 2 } });
        console.log(`Subscription ${subscriptionId} cancelled`);
      } else {
        handleApiError(response, dispatch);
      }
    });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US");
  };

  const isSubscriptionActive = (subscription) => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return subscription.current_period_end > currentTimestamp;
  };

  const toggleUpdateProfile = () => {
    dispatch(
      openDrawer("EditProfile", {
        firstName: advisorProfile.firstname,
        lastName: advisorProfile.lastname,
        emailid: advisorProfile.emailid,
        companyname: advisorProfile.companyname,
        crd_number: advisorProfile.crd_number,
        contactno: advisorProfile.contactno,
      })
    );
    setShowUpdateProfile(!showUpdateProfile);
  };

  const getInitials = (firstName, lastName) => {
    return `${firstName ? firstName[0].toUpperCase() : ""}${
      lastName ? lastName[0].toUpperCase() : ""
    }`;
  };

  if (isLoading) {
    return (
      <div className="loading-overlay">
        <Loader />
      </div>
    );
  }

  if (
    subscriptionsDetails.subscription_status !== "active" &&
    subscriptionsDetails.subscription_plan_type === "No Active Plan"
  ) {
    return (
      <Redirect to={{ pathname: "/OnboardScreen", state: { stage: 2 } }} />
    );
  }

  const handleSubscriptionUpgrade = (plan_name) => {
    let message
    if(subscriptionsDetails.subscription_plan_type === 'Adaptive Advisor Pro Plan'){
      message = "Upon downgrade, all portfolios will be archived for 30 days. You may select up to 10 portfolios to keep active. Each active portfolio can have up to 100 tickers. After 30 days, all non-selected (archived) portfolios will be permanently removed from your access. Do you wish to continue?"
    } 
    else{
      message = "Are you sure you wish to upgrade your Subscription?"
    }
    dispatch(
      showConfirmNotification(
        message,
        () => redirectToPayment(plan_name)
      )
    );
  };

  const handleCustomerPortalSession = () => {
    setIsLoading(true);
    CreateCustomerPortalSession(window.location.href).then((response) => {
      if (response) {
        setIsLoading(false);
        TagManager.dataLayer({
          dataLayer: {
          event: "Used Customer Portal",
          userType: sessionStorage.getItem("selectedAcType"),
          user_id: sessionStorage.getItem("clientId"),
          },
      });
        document.body.classList.add('fade-out');
        requestAnimationFrame(() => {
          window.location.href = response;
        });

      } else {
        handleApiError(response, dispatch);
      }
    })
  };
  
  return (
    <>
      <div className="profiledata">
        <div className="toast-container">
          <Toaster />
        </div>
        <div className="advisorcard">
          <a className="user-info">
            <span className="user-avatar">
              {profileImage ? (
                <Avatar
                  alt="User Avatar"
                  src={profileImage}
                  sx={{ width: 80, height: 80 }}
                />
              ) : (
                getInitials(advisorProfile.firstname, advisorProfile.lastname)
              )}
            </span>
          </a>
          <div className="username">
            <h4>
              {advisorProfile.firstname} {advisorProfile.lastname}
            </h4>
            <div className="designations">
              <a>
                <img src="./Assets/Mail.svg" alt="Email" />{" "}
                <span>{advisorProfile.emailid}</span>
              </a>
              <a>
                <img src="./Assets/Firm.svg" alt="Company" />{" "}
                <span>{advisorProfile.companyname}</span>
              </a>
              <a>
                <span>Contact Number: {advisorProfile.contactno}</span>
              </a>
              <a>
                <span>CRD Number: {advisorProfile.crd_number}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="profileaction">
          <button  className={`ai-btn blue solid ${whiteListCustomerFlag ? 'disabled' : ''}`} disabled={whiteListCustomerFlag} onClick={handleCustomerPortalSession}><img src="./Assets/Lock.svg" />Manage Payments</button>
          <button className="ai-btn blue solid" onClick={toggleUpdateProfile}>
            <img src="./Assets/EditUser.svg" />
            Edit Profile
          </button>
        </div>
      </div>
      <div className="profileoption">
        {activePlan && subscriptionsDetails.subscription_status === "active" ? (
        <>
          <div className="plan">
            <div className="planhead">
                <h3>Active Plan</h3>
                {isSubscriptionActive(activePlan) && (
                <button
                    className={`ai-btn red solid ${whiteListCustomerFlag ? 'disabled' : ''}`}
                    disabled={whiteListCustomerFlag}
                    onClick={() => handleCancelSubscription(activePlan.id)}
                >
                    Cancel Subscription
                </button>
                )}
            </div>
            <div className="planinfo">
              <div className="point">
                <div>{advisorProfile.emailid}</div>
              </div>
              {!whiteListCustomerFlag && (
                <>
                  <div className="point">
                    Auto-renewed On - {formatDate(activePlan.current_period_end)}
                  </div>
                  <div className="point">Amount - ${(activePlan.plan_amount / 100).toFixed(2)}</div>
                </>
              )}
              <div className="point">
                Plan Type - {subscriptionsDetails.subscription_plan_type}
              </div>
              <div className="point">
                Portfolio Limit -{" "}
                {subscriptionsDetails.subscription_plan_active_portfolio_limit !== 'Infinity' ? subscriptionsDetails.subscription_plan_active_portfolio_limit: 'Unlimited'}
              </div>
              <div className="point">
                Portfolio Line Items Limit -{" "}
                {
                  subscriptionsDetails.subscription_plan_active_portfolio_line_items_limit !== 'Infinity' ? subscriptionsDetails.subscription_plan_active_portfolio_line_items_limit: 'Unlimited'
                }
              </div>
            </div>
            {/* <button className='ai-btn primary solid'>Renew Now</button> */}
          </div>
          <div className="ShieldCardcon pricing-card">
            {subscriptionPlanNames
              .filter(plan => plan.usertype === subscriptionsDetails.user_type)
              .map(plan => (
                <div className="pricecardshield" key={plan.stripe_name}>
                  {subscriptionsDetails.subscription_plan_type === plan.stripe_name && (
                    <div className="popular-tag">
                      <span>Active Subscription</span>
                    </div>
                  )}
                  <div className="card-content-investor">
                    <div className="top-heading-in">
                      <div className="in-icon">
                        <img
                          src={`./Assets/${plan.icon}.svg`}
                          alt={`${plan.screen_name} icon`}
                          role="img"
                          width={30}
                          height={30}
                        />
                      </div>
                      <h3>{plan.screen_name}</h3>
                    </div>
                    <div className="price-listing">
                      <div className="price-top">
                        <p className="price-number">{plan.price}</p>
                        <div className="web-tag">
                          <span>-15% Discount</span>
                        </div>
                      </div>
                      <div className="price-btm">
                        <p>{plan.price_desc}</p>
                      </div>
                    </div>
                    {subscriptionsDetails.subscription_plan_type !== plan.stripe_name && (
                      <a
                        className={`button card-btn ${whiteListCustomerFlag ? 'disabled' : ''}`}
                        onClick={whiteListCustomerFlag ? (e) => e.preventDefault() : () => handleSubscriptionUpgrade(plan.stripe_name)}
                      >
                        {subscriptionsDetails.subscription_plan_type === 'Adaptive Advisor Pro Plan' ? 'Downgrade Plan' : 'Upgrade Plan'}
                      </a>
                    )}
                  </div>
                  <div className="marking-list">
                    <ul>
                      {plan.features.map((feature, index) => (
                        <li key={index} className={feature === 'No Call Writing Optimization' ? 'close' : ''}>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
          </div>

        </>
        ) : (
          <p>No active subscription found.</p>
        )}
        {/* <div className='history'>
                    <h4>View Purchase history </h4>
                    <button className='ai-link'>View Purchase history</button>
                </div> */}
        {/* <span className='divider'></span> */}
        {/* <div className='history'>
                    <div className='closeacc'><h4>Closing your account </h4><span>Once you delete your account, there is no going back. Please be certain.</span></div>
                    <button className='ai-btn red solid'>Delete Account</button>
                </div> */}
      </div>
      {activeDrawer && <UpdateProfile />}
    </>
  );
}

export default AdvisorProfile;
