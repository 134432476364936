// rootReducer.js

import { combineReducers } from 'redux';
import drawerReducer from './drawerReducer';
import portfolioReducer from './portfolioReducer';
import portfolioDetailsReducer from './portfolioDetailsReducer';
import notificationReducer from './notificationReducer';
import availableTickersReducer from './availableTickersReducer';
import { RESET_STATE } from '../actions/actionTypes'
import advisorProfileReducer from './advisorProfileReducer';
import pdfDataReducer from './pdfDataReducer';
import plaidReducer from './plaidReducer';
import optionsReducer from './OptionDataReducer';
import selectionReducer from './selectionReducer';
import pdfSummaryReducer from './pdfSummaryReducer';
import userPictureReducer from './userPictureReducer';
import shieldDataReducer from './shieldDataReducer';
import userSubscriptionReducer from './userSubscriptionReducer';
import optimizerReducer from './optimizerReducer';

const appReducer = combineReducers({
    portfolio: portfolioReducer,
    drawer: drawerReducer,
    portfolioDetails: portfolioDetailsReducer,
    notification: notificationReducer,
    availableTickers:availableTickersReducer,
    advisorProfile:advisorProfileReducer,
    pdfData: pdfDataReducer,
    plaid:plaidReducer,
    options: optionsReducer,
    selection: selectionReducer,
    pdfSummary:pdfSummaryReducer,
    userPicture:userPictureReducer,
    shieldData: shieldDataReducer,
    userSubscription: userSubscriptionReducer,
    optimizer: optimizerReducer
});

const rootReducer = (state, action) => {
    if (action.type === RESET_STATE) {
        state = undefined; // Resets the state to initial values
    }

    return appReducer(state, action);
};

export default rootReducer;
