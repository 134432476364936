import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import OnboardClient from '../../Components/OnboardClientPortfolio/OnboardClient';
import './OnBoardClientPortfolioScreen.css';
import { useSelector } from 'react-redux';

function OnBoardClientPortfolioScreen() {
    const history = useHistory();
    const subscriptionsDetails = useSelector(state => state.userSubscription.subscriptionData) || {};
    useEffect(() => {
        if (subscriptionsDetails.subscription_status === 'active' && history.location.state?.stage !== 3) {
            history.push('/ClientPortfolios');
        }
    }, [history,subscriptionsDetails]);

    return (
        <>
            <main className="onboard_wrap">
                <OnboardClient />
            </main>
        </>
    );
}

export default OnBoardClientPortfolioScreen;
