import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleItemSelection,
  setAllSelected,
  initializeSelections,
  setGlobalCapLevel,
  setExpDate,
  setLocalFilters,
} from '../../actions/selectionActions';
import {
  fetchPortfolioDetailsIfNeeded,
  setPortfolioNeedsUpdate
} from '../../actions/portfolioDetailsActions';
import Loader from '../../Components/Loader/Loader';
import './Dashboard.scss';
import DashboardSection from '../ChainDashboard/ChainDashboard';
import { formatDate } from '../../Utilities/FormatContract';
import ShoppingCart from '../ShieldRecommendation/ShoppingCart';
import { addZeroes } from "../../Utilities/AddZeros";
import { RollOrSell } from '../../Apis/RollAndSellHandler';
import OptionChainOptimizer from '../../Apis/OptionChainOptimizer';
import moment from 'moment';
import { showNotification } from '../../actions/notificationActions';
import { getNextThirdFriday } from '../../Utilities/DateUtils';
import { v4 as uuidv4 } from 'uuid';
import CustomDatePicker from '../ShieldRecommendationv2/CustomDatePicker';

function Dashboard({ portfolioId, isEmpty, portfolioDetails, tabType, visibleCartItems, setVisibleCartItems, recommendationMode, protectionLevel, stockOptions }) {
  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const initializeExisitingSelections = (tabType) => {
    const savedCallSelections = sessionStorage.getItem('currentSelections');
    const savedPutSelections = sessionStorage.getItem('currentPutSelections');

    if (tabType === 'CallWriting') {
      return savedCallSelections ? JSON.parse(savedCallSelections) : {};
    } else if (tabType === 'PutBuying') {
      return savedPutSelections ? JSON.parse(savedPutSelections) : {};
    } else {
      return {}; // Default case if tabType is unknown
    }
  };


  const hedgeable = portfolioDetails?.PortfolioLineItem?.Hedgeable || [];
  const unhedgeable = portfolioDetails?.PortfolioLineItem?.Unhedgeable || [];
  console.log("hedgeable", hedgeable)
  const dispatch = useDispatch();
  const closeBtnRef = useRef(null);
  const [error, setError] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const {
    selectedItems = {},
    allSelected = false,
    GlobalCapLevel = 100,
    // ExpDate = moment(getNextThirdFriday(moment.utc().add(1, 'month'))).format('YYYY-MM-DD'),
    ExpDate = getNextThirdFriday().toISOString().split('T')[0],
    localFilters = {} 
  } = useSelector((state) => state.selection[tabType] || {});

  const [FilteredData, setFilteredData] = useState({});
  const [TransfomedData, setTransformedData] = useState([]);
  const [totalPortfolioValue, setTotalPortfolioValue] = useState(0);
  const [totalPremium, setTotalPremium] = useState(0);
  const [showDashboardSection, setShowDashboardSection] = useState(false)
  const [priceToShow, setPriceToShow] = useState(tabType === 'CallWriting' ? 'Bid' : 'Ask');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { data } = useSelector(state => state.options);
  const [optionData, setOptionData] = useState({});
  const [shoppingFlag, setShoppingFlag] = useState(false)
  const [collapseStates, setCollapseStates] = useState({});
  const [currentSelections, setCurrentSelections] = useState(() => initializeExisitingSelections(tabType));
  const [useGlobalStdDev, setUseGlobalStdDev] = useState(false);
  const [globalStdDevLevel, setGlobalStdDevLevel] = useState(1.0); // Default to 1 standard deviation
  const [selectedRowPerTicker, setSelectedRowPerTicker] = useState({});

  const [isOptimizerLoading, setIsOptimizerLoading] = useState(false);
  const [optimizerData, setOptimizerData] = useState(JSON.parse(sessionStorage.getItem('optimizerData')) ? JSON.parse(sessionStorage.getItem('optimizerData')) : {});
  const [optimizedPremium, setOptimizedPremium] = useState(sessionStorage.getItem('optimizedPremium') ? sessionStorage.getItem('optimizedPremium') : 0);
  const totalCost = useSelector(state => state.selection[tabType].totalCost);

  const [selectedRecommendations, setSelectedRecommendations] = useState(() => {
    // Initialize from sessionStorage
    const savedSelections = sessionStorage.getItem('selectedRecommendations');
    return savedSelections ? JSON.parse(savedSelections) : {};
  });
  const [contractQuantities, setContractQuantities] = useState({});
  
  const subscriptionsDetails =
    useSelector((state) => state.userSubscription.subscriptionData) || {};

  console.log("selctionnnss", sessionStorage.getItem('selectedRecommendations'))

  useEffect(() => {
    if (Object.keys(selectedRowPerTicker).length > 0) {
      sessionStorage.setItem('selectedRowPerTicker', JSON.stringify(selectedRowPerTicker));
    }
  }, [selectedRowPerTicker]);

  useEffect(() => {
    if (selectedRecommendations) {
      sessionStorage.setItem('selectedRecommendations', JSON.stringify(selectedRecommendations));
      console.log('Saved to sessionStorage:', selectedRecommendations); // Debugging log
    }
  }, [selectedRecommendations]);

  useEffect(() => {
    const savedSelectedRow = sessionStorage.getItem('selectedRowPerTicker');
    if (savedSelectedRow) {
      setSelectedRowPerTicker(JSON.parse(savedSelectedRow));
    }
  }, []);


  const updateTotalCost = (newTotalCost) => {
    sessionStorage.setItem('totalCost', JSON.stringify(newTotalCost));
    const event = new Event('totalCostUpdated');
    window.dispatchEvent(event);
  };

  const preparePortfolioData = () => {
    const portfolioData = {};
    portfolioDetails?.PortfolioLineItem?.Hedgeable?.forEach(item => {
      if (item.type === 'Stock') {
        portfolioData[item.Ticker] = Math.floor(item.Quantity / 100);
      }
    });
    return portfolioData;
  };

  const handleOptimizerClick = () => {
    setIsOptimizerLoading(true);
    const portfolioData = preparePortfolioData();
    const target = parseInt(sessionStorage.getItem('MarketCost'), 10) || 0;

    OptionChainOptimizer(portfolioData, ExpDate, target)
      .then((response) => {
        if (response && Object.keys(response).length > 0) {
          const updatedOptimizerData = {};
          let premiumOptimized = 0;

          Object.keys(response).forEach((ticker) => {
            const bestOption = findBestOptionByCapLevel(
              optionData[ticker] || [],
              response[ticker]['PercentofClose'],
              ExpDate,
              ticker
            );

            if (bestOption) {
              updatedOptimizerData[ticker] = {
                ...response[ticker],
                bestOption,
              };
              premiumOptimized += bestOption.CloseBidPrice * 100 * response[ticker]['optimal_contracts'];
            }
          });
          console.log("updatedOptimizerData", updatedOptimizerData)
          setOptimizerData(updatedOptimizerData);
          setOptimizedPremium(premiumOptimized);

          sessionStorage.setItem('optimizerData', JSON.stringify(updatedOptimizerData));
          sessionStorage.setItem('optimizedPremium', JSON.stringify(premiumOptimized));
          sessionStorage.setItem('optimizerPortfolioId', portfolioId);
          // showNotification('Optimization successful!', 'success', 'toaster');

          return RollOrSell({
            portfolio_id: portfolioId,
            optimizer: true,
            option_contracts: []
          });
        } else {
          showNotification('Optimizer API returned empty data.', 'error', 'toaster');
          return Promise.reject('No data from optimizer');
        }
      })
      .then((rollOrSellResponse) => {
        if (rollOrSellResponse?.result === true) {
          // showNotification('RollOrSell executed successfully!', 'success', 'toaster');

          dispatch(setPortfolioNeedsUpdate(portfolioId));
          dispatch(fetchPortfolioDetailsIfNeeded(portfolioId));
        } else {
          showNotification('Failed to execute RollOrSell.', 'error', 'toaster');
        }
      })
      .catch((error) => {
        console.error('Error during optimization:', error);
        showNotification('Error during optimization process.', 'error', 'toaster');
      })
      .finally(() => {
        setIsOptimizerLoading(false);
      });
  };

  const handleRowClick = (e, ticker, option_description, num_contracts, expiration_date, strike, bid_price, stock_price, key) => {
    if (e.target.closest('.icon-button.cancel')) {
      handleCancelOption(ticker, key)
      return;
    }
    if (e.target.closest('.icon-button.roll')) {
      handleLocalFilterChange(ticker, (strike / stock_price * 100).toFixed(0), expiration_date)
      setSelectedRowPerTicker(prevState => {

        if (prevState[ticker]?.option_description === option_description) {
          const newState = { ...prevState };
          delete newState[ticker];
          return newState;
        } else {
          return {
            ...prevState,
            [ticker]: { option_description, num_contracts, expiration_date, strike, bid_price }
          };
        }
      });
    }
  };




  const headingStyle = {
    textDecoration: 'underline',
    cursor: 'pointer'
  };

  const toggleCollapse = (ticker) => {
    setCollapseStates(prev => ({
      ...prev,
      [ticker]: !prev[ticker]
    }));
  };

  const filteredTickers = JSON.parse(sessionStorage.getItem('filteredTickers')) || [];
  // const stockOptions = JSON.parse(sessionStorage.getItem('stockOptions')) || [];
  const [filteredStockHoldings, setFilteredStockHoldings] = useState(() => mapStockOptionsToHoldings(stockOptions));
  console.log("filteredStockHoldings", filteredStockHoldings)

  useEffect(() => {
    // Update `filteredStockHoldings` when `stockOptions` changes
    setFilteredStockHoldings(mapStockOptionsToHoldings(stockOptions));
  }, [stockOptions]);

  function extractAndAlignOptionContracts(hedgeable, unhedgeable) {
    const alignedContracts = {};

    hedgeable.forEach(item => {
      const ticker = item.Ticker;
      console.log(" utemm", item, hedgeable)

      const regex = /^([A-Z]+)(\d{4})-(\d{2})-(\d{2})([CP])(\d+(\.\d{1,2})?)$/;
      const match = ticker.match(regex);

      if (match) {
        const [, stockTicker, year, month, day, type, strikePrice] = match;

        const optionInfo = {
          stockTicker,
          expiration_date: `${year}-${month}-${day}`,
          type,
          strike: parseFloat(strikePrice).toFixed(2),
          bid_price: item.ClosePrice ? parseFloat(item.ClosePrice) : null,
          num_contracts: item.Quantity ? parseInt(item.Quantity, 10) : null,
          totalValue: item.TotalValue ? parseFloat(item.TotalValue) : null,
          description: ticker || null,
          contractExpired: item.ContractExpired,
          isHedgeable: true,
          status: item.status
        };

        // Initialize the array for the stock ticker if it doesn't exist
        if (!alignedContracts[stockTicker]) {
          alignedContracts[stockTicker] = [];
        }

        // Push the option info to the respective stock ticker array
        alignedContracts[stockTicker].push(optionInfo);
      }
    });

    // Handle Unhedgeable (expired or closed) contracts
    unhedgeable.forEach(item => {
      const ticker = item.Ticker;

      // Same logic for expired contracts
      const regex = /^([A-Z]+)(\d{4})-(\d{2})-(\d{2})([CP])(\d+(\.\d{1,2})?)$/;
      const match = ticker.match(regex);

      if (match) {
        const [, stockTicker, year, month, day, type, strikePrice] = match;

        const optionInfo = {
          stockTicker,
          expiration_date: `${year}-${month}-${day}`,
          type,
          strike: parseFloat(strikePrice).toFixed(2),
          bid_price: item.ClosePrice ? parseFloat(item.ClosePrice) : null,
          num_contracts: item.Quantity ? parseInt(item.Quantity, 10) : null,
          totalValue: item.TotalValue ? parseFloat(item.TotalValue) : null,
          description: ticker || null,
          contractExpired: item.ContractExpired, // Add contract expired status
        };

        if (!alignedContracts[stockTicker]) {
          alignedContracts[stockTicker] = [];
        }

        // Push the expired option info to the respective stock ticker array
        alignedContracts[stockTicker].push(optionInfo);
      }
    });


    console.log("alignedContracts", alignedContracts)
    return alignedContracts;
  }

  function mapStockOptionsToHoldings(stockOptions) {
    const alignedContracts = {};

    if (stockOptions && Array.isArray(stockOptions.SecDesc) && stockOptions.SecDesc.length > 0) {
      stockOptions.SecDesc.forEach((desc, index) => {
        const stockTickerMatch = desc.match(/^[A-Z]+/);
        const expirationDateMatch = desc.match(/\d{4}-\d{2}-\d{2}/);

        if (stockTickerMatch && expirationDateMatch) {
          const stockTicker = stockTickerMatch[0];
          const expiration_date = expirationDateMatch[0];
          const typeMatch = desc.match(/([CP])(\d+(\.\d{1,2})?)$/);
          const typefilter = typeMatch ? typeMatch[1] : null;
          const type = 'P';

          console.log("typefilter stockOptions", typefilter, stockOptions)
          const optionInfo = {
            stockTicker,
            expiration_date,
            strike: parseFloat(stockOptions.Strike?.[index] || 0).toFixed(2),
            bid_price: stockOptions.LastBidPrice?.[index] || stockOptions.ClosePrice?.[index] || 0,
            ask_price: stockOptions.LastAskPrice?.[index] || 0,
            num_contracts: stockOptions.ConQty?.[index] || 0,
            totalValue: stockOptions.NotionalVal?.[index] || 0,
            type,
            description: desc,
            contractExpired: false,
            status: 'Pending Open'
          };

          // Initialize the array for the stock ticker if it doesn't exist
          if (!alignedContracts[stockTicker]) {
            alignedContracts[stockTicker] = [];
          }

          alignedContracts[stockTicker].push(optionInfo);
        }
      });
    }

    return alignedContracts;
  }

  const loadAndMergeExistingOptionHoldings = (tabType) => {
    const portfolioContracts = extractAndAlignOptionContracts(hedgeable, unhedgeable);
    const savedHoldings = JSON.parse(sessionStorage.getItem('existingOptionHoldings')) || {};

    // Get holdings specific to the active tab
    const existingHoldings = savedHoldings[tabType] || {};

    // Merge existing holdings with portfolio contracts for the active tab
    const mergedHoldings = { ...portfolioContracts };

    Object.keys(existingHoldings).forEach(ticker => {
      if (mergedHoldings[ticker]) {
        // Update or merge contracts for existing tickers
        existingHoldings[ticker].forEach(existingContract => {
          const matchIndex = mergedHoldings[ticker].findIndex(
            contract => contract.description === existingContract.description
          );

          if (matchIndex !== -1) {
            // Update contract status in merged holdings
            mergedHoldings[ticker][matchIndex] = existingContract;
          } else {
            // Add new contracts to merged holdings
            mergedHoldings[ticker].push(existingContract);
          }
        });
      } else {
        // Add tickers not found in portfolio contracts
        mergedHoldings[ticker] = existingHoldings[ticker];
      }
    });
    // sessionStorage.setItem('existingOptionHoldings', JSON.stringify({ ...savedHoldings, [tabType]: mergedHoldings }));

    return mergedHoldings;
  };

  // Initialize existing_option_holdings state based on tab type
  const [existing_option_holdings, set_existing_option_holdings] = useState(() => loadAndMergeExistingOptionHoldings(tabType));

  // const [existing_option_holdings, set_existing_option_holdings] = useState(loadAndMergeExistingOptionHoldings());

  // const [existing_option_holdings, set_existing_option_holdings] = useState(extractAndAlignOptionContracts(hedgeable, unhedgeable));


  console.log("existing_option_holdings", existing_option_holdings)


  const handleStrikeChangeRoll = (ticker, key, newStrike) => {
    //calculate the percentage of strike/stock_price
    set_existing_option_holdings(prevData => ({
      ...prevData,
      [ticker]: {
        ...prevData[ticker],
        [key]: {
          ...prevData[ticker][key],
          strike: newStrike
        }
      }
    }));
  };

  const handleExpirationChangeRoll = (ticker, key, newExpiration) => {
    set_existing_option_holdings(prevData => ({
      ...prevData,
      [ticker]: {
        ...prevData[ticker],
        [key]: {
          ...prevData[ticker][key],
          expiration_date: newExpiration
        }
      }
    }));
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getStockQuantity = (ticker) => {
    const stock = portfolioDetails.PortfolioLineItem.Hedgeable.find(item => item.Ticker === ticker);
    return stock ? stock.Quantity : 0;
  };


  // const [selectedItems, setSelectedItems] = useState({});
  const [selectedTicker, setSelectedTicker] = useState(null);
  // const allSelected = Object.values(selectedItems).every(Boolean);
  const parseDate = (dateStr) => new Date(dateStr);
  const formatDateToYYYYMMDD = (date) => {
    return moment.utc(date).startOf('day').format('YYYY-MM-DD');
  };

  const changePriceToShow = (price) => {
    setPriceToShow(price);
    setDropdownVisible(false);
  };


  const findBestOptionByCapLevel = (options, capLevelPercentage, targetDateStr, ticker) => {
    let bestOption = null;
    const targetDate = parseDate(targetDateStr);
    const sortedOptions = options.sort((a, b) => {
      const dateDiffA = Math.abs(parseDate(a.ExpirationDate) - targetDate);
      const dateDiffB = Math.abs(parseDate(b.ExpirationDate) - targetDate);

      if (dateDiffA !== dateDiffB) {
        return dateDiffA - dateDiffB;
      } else {
        const strikeDiffA = Math.abs(a.Strike - (a.ClosePrice * capLevelPercentage / 100));
        const strikeDiffB = Math.abs(b.Strike - (b.ClosePrice * capLevelPercentage / 100));
        return strikeDiffA - strikeDiffB;
      }
    });
    bestOption = sortedOptions[0];
    console.log("bestOption", ticker, capLevelPercentage, bestOption)
    return bestOption;
  };

  const findBestOptionByStdDev = (options, currentPrice, nStdDev, targetDateStr, type) => {
    console.log("The Std dev level is: ", nStdDev)
    const targetDate = parseDate(targetDateStr);

    // Filter options by the specified expiry date
    let optionsForExpiry = options.filter(option => {
      const optionExpiryDate = parseDate(option.ExpirationDate);
      return optionExpiryDate.toISOString().split('T')[0] === targetDate.toISOString().split('T')[0];
    });

    // If no exact match for expiryDate is found, find the closest expiry date
    if (optionsForExpiry.length === 0) {
      let closestExpiry = null;
      let minDateDifference = Infinity;

      options.forEach(option => {
        const optionExpiryDate = parseDate(option.ExpirationDate);
        const dateDifference = Math.abs(optionExpiryDate - targetDate);
        if (dateDifference < minDateDifference) {
          minDateDifference = dateDifference;
          closestExpiry = optionExpiryDate;
        }
      });

      // Filter again using the closest expiry date found
      if (closestExpiry) {
        optionsForExpiry = options.filter(option => {
          const optionExpiryDate = parseDate(option.ExpirationDate);
          return optionExpiryDate.toISOString().split('T')[0] === closestExpiry.toISOString().split('T')[0];
        });
      }
    }

    let selectedOption = null;
    let minDifference = Infinity;

    optionsForExpiry.forEach(option => {
      const T = (parseDate(option.ExpirationDate) - new Date()) / (365 * 24 * 60 * 60 * 1000); // Time to expiry in years
      const stdMove = currentPrice * option.HistoricalVolatility * Math.sqrt(T);

      const targetStrike = type === 'CallWriting'
        ? currentPrice + nStdDev * stdMove // Upwards for CALLs
        : currentPrice - nStdDev * stdMove; // Downwards for PUTs

      const difference = Math.abs(option.Strike - targetStrike);

      if (difference < minDifference) {
        minDifference = difference;
        selectedOption = option;
      }
    });

    return selectedOption;
  };


  const calculateTotalPremium = () => {
    return Object.entries(FilteredData).reduce((total, [ticker, [bestOption]]) => {
      if (selectedItems[ticker]) { // Check if the item is selected
        const portfolioEntry = TransfomedData[ticker];
        const shares = portfolioEntry ? portfolioEntry.quantity : 0;
        const noOfContracts = Math.floor(shares / 100);
        total += parseFloat(tabType === 'CallWriting' ? bestOption.CloseBidPrice : bestOption.CloseAskPrice) * 100 * noOfContracts // Adjust this line according to how you access the premium value
      }
      return total;
    }, 0);
  };


  const handleSelectionChange = (ticker) => {
    dispatch(toggleItemSelection(ticker, !selectedItems[ticker], tabType));
  };

  const persistExistingOptionHoldings = (updatedHoldings, tabType) => {
    const savedHoldings = JSON.parse(sessionStorage.getItem('existingOptionHoldings')) || {};
    savedHoldings[tabType] = updatedHoldings;
    sessionStorage.setItem('existingOptionHoldings', JSON.stringify(savedHoldings));
  };


  useEffect(() => {
    if (Object.keys(selectedItems).length === 0 && portfolioDetails?.PortfolioLineItem?.Hedgeable) {
      const initialData = portfolioDetails.PortfolioLineItem.Hedgeable.reduce((acc, item) => {
        acc[item.Ticker] = tabType === 'CallWriting';
        return acc;
      }, {});
      dispatch(initializeSelections(initialData, tabType));
    }
  }, [dispatch, portfolioDetails?.PortfolioLineItem?.Hedgeable, tabType]);

  useEffect(() => {
    let newTotalPremium = calculateTotalPremium();
    // console.log("the new total premium is", newTotalPremium);
    if (newTotalPremium !== totalPremium) {
      setTotalPremium(newTotalPremium);
    }
  }, [FilteredData, selectedItems]);


  const handleSelectAllChange = () => {
    if (recommendationMode) {
      const isChecked = filteredTickers.every(ticker =>
        selectedRecommendations[ticker] &&
        selectedRecommendations[ticker].length === (filteredStockHoldings[ticker] || []).length
      );

      // When unselecting all
      if (isChecked) {
        set_existing_option_holdings((prevHoldings) => {
          const updatedHoldings = { ...prevHoldings };

          // Remove all recommendations from `existing_option_holdings` if ticker is not already selected
          filteredTickers.forEach((ticker) => {
            if (updatedHoldings[ticker]) {
              updatedHoldings[ticker] = updatedHoldings[ticker].filter(
                holding => !filteredStockHoldings[ticker].some(
                  recommended => recommended.description === holding.description &&
                    recommended.expiration_date === holding.expiration_date &&
                    recommended.strike === holding.strike
                )
              );

              // Clean up if no holdings remain for the ticker
              if (updatedHoldings[ticker].length === 0) delete updatedHoldings[ticker];
            }
          });

          persistExistingOptionHoldings(updatedHoldings, tabType); // Persist updated state
          return updatedHoldings;
        });

        // Clear all selected recommendations without unselecting already selected tickers
        const newSelectedRecommendations = {};
        filteredTickers.forEach((ticker) => {
          if (!existing_option_holdings[ticker]) {
            dispatch(toggleItemSelection(ticker, false, tabType));
          }
        });
        setSelectedRecommendations(newSelectedRecommendations);
      } else {
        // When selecting all
        set_existing_option_holdings((prevHoldings) => {
          const updatedHoldings = { ...prevHoldings };

          // Add all recommendations to `existing_option_holdings`
          filteredTickers.forEach((ticker) => {
            const allContracts = filteredStockHoldings[ticker] || [];

            if (!updatedHoldings[ticker]) {
              updatedHoldings[ticker] = [];
            }

            allContracts.forEach((contract) => {
              const contractExists = updatedHoldings[ticker].some(
                (existingContract) =>
                  existingContract.description === contract.description &&
                  existingContract.expiration_date === contract.expiration_date &&
                  existingContract.strike === contract.strike
              );

              if (!contractExists) {
                updatedHoldings[ticker].push(contract);
              }
            });
          });

          persistExistingOptionHoldings(updatedHoldings, tabType); // Persist updated state
          return updatedHoldings;
        });

        // Select all recommendations without unselecting already selected tickers
        const newSelectedRecommendations = {};
        filteredTickers.forEach((ticker) => {
          if (!selectedItems[ticker]) {
            dispatch(toggleItemSelection(ticker, true, tabType));
          }
          newSelectedRecommendations[ticker] = filteredStockHoldings[ticker].map((_, i) => i);
        });
        setSelectedRecommendations(newSelectedRecommendations);
      }
    } else {
      // Default case for non-recommendation mode
      const tickers = Object.keys(FilteredData);  // Use all tickers in FilteredData, whether in recommendation mode or not
      dispatch(setAllSelected(!allSelected, tabType, tickers));
    }
  };

  useEffect(() => {
    let newTotalValue = 0;
    let newTickerData = {};
    Object.values(TransfomedData).forEach(stock => {
      newTotalValue += stock.quantity * stock.price;
    });

    setTotalPortfolioValue(newTotalValue);
    sessionStorage.setItem('totalPortfolioValue', JSON.stringify(newTotalValue));

    let newTotalPremium = 0;

    Object.entries(FilteredData).forEach(([ticker, [bestOption]]) => {
      if (bestOption) {
        // Check if an entry exists in localFilters for this ticker and use its premium if available
        if (localFilters[ticker] && localFilters[ticker].premium !== undefined) {
          newTotalPremium += localFilters[ticker].premium;
          const noOfContracts = localFilters[ticker].noOfContracts;
          newTickerData[ticker] = {
            noOfContracts,
            premium: localFilters[ticker].premium,
            premiumPercentage: localFilters[ticker].premiumPercentage
          };
        } else {
          // Fallback to calculating premium if no entry exists in localFilters for this ticker
          const portfolioEntry = TransfomedData[ticker];
          const shares = portfolioEntry ? portfolioEntry.quantity : 0;
          const noOfContracts = Math.floor(shares / 100);
          const premium = tabType === 'CallWriting' ? parseFloat(bestOption.CloseBidPrice) * 100 * noOfContracts : parseFloat(bestOption.CloseAskPrice) * 100 * noOfContracts;
          newTotalPremium += premium;
          newTickerData[ticker] = {
            noOfContracts,
            premium: premium.toFixed(2),
            premiumPercentage: (premium / (shares * bestOption.ClosePrice)) * 100
          };
        }
      }
    });
    // console.log("the new total premium is", newTotalPremium);
    setTotalPremium(newTotalPremium);
    tabType === 'CallWriting' ? sessionStorage.setItem('totalPremium', JSON.stringify(newTotalPremium)) : sessionStorage.setItem('totalCost', JSON.stringify(newTotalPremium));
    sessionStorage.setItem('tickerData', JSON.stringify(newTickerData));

  }, [FilteredData, localFilters, TransfomedData]);

  const handleLocalFilterChange = (ticker, newCapLevel, newExpDate, newStdDevLevel) => {
    const updatedCapLevel = newCapLevel || localFilters[ticker]?.capLevel || GlobalCapLevel;
    const updatedExpDate = newExpDate || localFilters[ticker]?.expDate || ExpDate;
    const updatedStdDevLevel = newStdDevLevel || localFilters[ticker]?.stdDevLevel || globalStdDevLevel;

    const newLocalFilters = {
      ...localFilters,
      [ticker]: {
        ...localFilters[ticker],
        capLevel: updatedCapLevel,
        expDate: updatedExpDate,
        stdDevLevel: updatedStdDevLevel,
      }
    };

    dispatch(setLocalFilters(newLocalFilters, tabType));
    updateFilteredDataForTicker(ticker, updatedCapLevel, updatedExpDate, updatedStdDevLevel);
  };


  const updateFilteredDataForTicker = (ticker, newCapLevel, newExpDate, newStdDevLevel) => {
    const tickerOptions = optionData[ticker];
    const portfolioEntry = TransfomedData[ticker];
    const currentPrice = portfolioEntry ? portfolioEntry.price : 0;

    if (!tickerOptions) return;

    let bestOption;

    if (useGlobalStdDev) {
      console.log("About to call findBestOptionByStdDev with nStdDev:", newStdDevLevel);
      bestOption = findBestOptionByStdDev(tickerOptions, currentPrice, newStdDevLevel, newExpDate, tabType);
    } else {
      bestOption = findBestOptionByCapLevel(tickerOptions, newCapLevel, newExpDate, ticker);
    }

    if (bestOption) {
      setFilteredData(prevFilteredData => ({
        ...prevFilteredData,
        [ticker]: [bestOption]
      }));

      const parsedDate = parseDate(bestOption.ExpirationDate);
      const formattedDate = formatDateToYYYYMMDD(parsedDate);
      const updatedFilters = {
        ...localFilters,
        [ticker]: {
          ...localFilters[ticker],
          expDate: formattedDate,
          capLevel: newCapLevel,
          stdDevLevel: newStdDevLevel,
        }
      };
      dispatch(setLocalFilters(updatedFilters, tabType));
    }
  };


  const GoToOptionChainScreen = (ticker) => {
    setSelectedTicker(ticker);
    setShowDashboardSection(true);
  }

  const handleGlobalChange = (newCapLevel, newExpDate) => {
    if (newCapLevel !== undefined) {
      dispatch(setGlobalCapLevel(newCapLevel, tabType));
    }
    if (newExpDate !== undefined) {
      dispatch(setExpDate(newExpDate, tabType));
    }
    Object.entries(optionData).forEach(([ticker, options]) => {
      const bestOption = findBestOptionByCapLevel(options, newCapLevel, newExpDate, ticker);
      if (bestOption) {
        setFilteredData(prevFilteredData => ({ ...prevFilteredData, [ticker]: [bestOption] }));
      }
    });
  };


  const filterData = (newCapLevel, newExpDate, stdDevLevel) => {
    if (!optionData) {
      console.error('Option data is not available for filtering');
      return;
    }

    const newData = {};
    let selectionsUsed = false;

    Object.entries(optionData).forEach(([ticker, options]) => {
      let bestOption;
      const selection = currentSelections[ticker];
      const portfolioEntry = TransfomedData[ticker];
      const currentPrice = portfolioEntry ? portfolioEntry.price : 0;

      if (selection) {
        selectionsUsed = true;
        bestOption = options.find(opt => opt.Strike === selection.strike && opt.ExpirationDate === selection.expiry);
      } else {
        if (useGlobalStdDev) {
          bestOption = findBestOptionByStdDev(options, currentPrice, stdDevLevel, newExpDate, tabType); // Use passed stdDevLevel
        } else {
          bestOption = findBestOptionByCapLevel(options, newCapLevel, newExpDate, ticker);
        }
      }

      if (bestOption) {
        newData[ticker] = [bestOption];
      }
    });

    setFilteredData(newData);

    if (selectionsUsed) {
      sessionStorage.removeItem('currentSelections');
      setCurrentSelections({});
    }
  };




  function transformPortfolioData(hedgeable) {
    return hedgeable.reduce((acc, item) => {
      acc[item.Ticker] = { quantity: item.Quantity, price: item.ClosePrice };
      return acc;
    }, {});
  }

  useEffect(() => {
    setisLoading(true);
    try {
      const relevantData = tabType === 'CallWriting' ? data.C : data.P;
      setOptionData(relevantData || {});
      const transformedData = transformPortfolioData(portfolioDetails.PortfolioLineItem.Hedgeable);
      setTransformedData(transformedData);
      handleGlobalChange(GlobalCapLevel, ExpDate);
      setisLoading(false);
    } catch (error) {
      console.error("Error loading options data:", error);
      setisLoading(false);
    }
  }, [tabType, data]);


  useEffect(() => {
    if (optionData) {
      if (useGlobalStdDev) {
        filterData(null, ExpDate, globalStdDevLevel); // Pass null for capLevel when using stdDev
      } else {
        filterData(GlobalCapLevel, ExpDate, null); // Pass null for stdDev when using capLevel
      }
    }
  }, [optionData, GlobalCapLevel, ExpDate, globalStdDevLevel, useGlobalStdDev]);
  


  useEffect(() => {
    let newTotalPremium = 0;
    let newCartItems = [];

    Object.entries(FilteredData).forEach(([ticker, [bestOption]]) => {
      if (bestOption && selectedItems[ticker]) {
        const portfolioEntry = TransfomedData[ticker];
        const shares = portfolioEntry ? portfolioEntry.quantity : 0;
        const noOfContracts = localFilters[ticker]?.noOfContracts || Math.floor(shares / 100);
        // const noOfContracts = Math.floor(shares / 100);
        const premium = parseFloat(tabType === 'CallWriting' ? bestOption.CloseBidPrice : bestOption.CloseAskPrice) * 100 * noOfContracts;
        const formattedDate = formatDate(bestOption.ExpirationDate);
        const contractDisplay = `${ticker} ${formattedDate} $${bestOption.Strike}`;

        newCartItems.push({
          contract: contractDisplay,
          quantity: tabType === 'CallWriting' ? -noOfContracts : noOfContracts,
          premium: premium
        });

        newTotalPremium += premium;
      }
    });

    const cartItemsKey = tabType === 'CallWriting' ? 'callWritingCartItems' : 'putBuyingCartItems';
    sessionStorage.setItem(cartItemsKey, JSON.stringify(newCartItems));
    // console.log("the new total premium is", newTotalPremium);
    setTotalPremium(newTotalPremium);
    // const totalValueKey = tabType === 'CallWriting' ? 'totalPremium' : 'totalCost';
    // sessionStorage.setItem(totalValueKey, JSON.stringify(newTotalPremium));
    const totalValueKey = tabType === 'CallWriting' ? 'totalPremium' : 'totalCost';
    if (tabType === 'CallWriting') {
      sessionStorage.setItem('totalPremium', JSON.stringify(newTotalPremium));
    } else {
      updateTotalCost(newTotalPremium); // Use the new centralized function
    }

  }, [FilteredData, selectedItems, TransfomedData, localFilters, portfolioId]);


  useEffect(() => {
    let existingCartItems = JSON.parse(sessionStorage.getItem(tabType === 'CallWriting' ? 'callWritingCartItems' : 'putBuyingCartItems')) || [];
    existingCartItems = existingCartItems.filter(item => selectedItems[item.ticker]);

    let newTotalPremium = 0;
    let newCartItems = [...existingCartItems];
    Object.entries(FilteredData).forEach(([ticker, [bestOption]]) => {
      if (bestOption && selectedItems[ticker]) {
        const portfolioEntry = TransfomedData[ticker];
        const shares = portfolioEntry ? portfolioEntry.quantity : 0;
        const noOfContracts = localFilters[ticker]?.noOfContracts || Math.floor(shares / 100);
        const premium = parseFloat(tabType === 'CallWriting' ? bestOption.CloseBidPrice : bestOption.CloseAskPrice) * 100 * noOfContracts;
        const formattedDate = formatDate(bestOption.ExpirationDate);
        const contractDisplay = `${ticker} ${formattedDate} $${bestOption.Strike}`;

        newCartItems.push({
          ticker,
          contract: contractDisplay,
          quantity: tabType === 'CallWriting' ? -noOfContracts : noOfContracts,
          premium,
        });

        newTotalPremium += premium;
      }
    });


    const cartItemsKey = tabType === 'CallWriting' ? 'callWritingCartItems' : 'putBuyingCartItems';
    sessionStorage.setItem(cartItemsKey, JSON.stringify(newCartItems));
    console.log("the new total premium is", newTotalPremium);
    setTotalPremium(newTotalPremium);

    const totalValueKey = tabType === 'CallWriting' ? 'totalPremium' : 'totalCost';
    sessionStorage.setItem(totalValueKey, JSON.stringify(newTotalPremium));

  }, [FilteredData, selectedItems, TransfomedData, localFilters, portfolioId, existing_option_holdings, tabType]);

  useEffect(() => {
    // Update max contracts for all tickers in the portfolio whenever holdings or portfolio data changes
    Object.keys(TransfomedData).forEach((ticker) => {
      updateMaxContracts(ticker);
    });
  }, [TransfomedData, existing_option_holdings]);

  if (isLoading) {
    return (
      <div className="loading-overlay">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleGlobalFilterChange = (newCapLevel, newExpDate, newStdDevLevel) => {
    const updatedCapLevel = newCapLevel ?? GlobalCapLevel;
    const updatedExpDate = newExpDate ? moment.utc(newExpDate).startOf('day').format('YYYY-MM-DD') : ExpDate;
    const updatedStdDevLevel = newStdDevLevel ?? globalStdDevLevel;

    if (useGlobalStdDev) {
      setGlobalStdDevLevel(updatedStdDevLevel); // This state update is asynchronous
    } else {
      dispatch(setGlobalCapLevel(updatedCapLevel, tabType));
    }

    dispatch(setExpDate(updatedExpDate, tabType));
    filterData(updatedCapLevel, updatedExpDate, updatedStdDevLevel);

    const newLocalFilters = Object.keys(localFilters).reduce((acc, ticker) => {
      acc[ticker] = {
        ...localFilters[ticker],
        capLevel: updatedCapLevel,
        expDate: updatedExpDate,
        stdDevLevel: updatedStdDevLevel // Use updated value here
      };
      return acc;
    }, {});

    dispatch(setLocalFilters(newLocalFilters, tabType));
  };

  function handleContractChange(ticker, newNoOfContracts, shares, price) {
    const newNoOfContractsInt = parseInt(newNoOfContracts, 10);
    if (isNaN(newNoOfContractsInt) || newNoOfContractsInt < 1) return; // Early return on invalid input

    // Assuming price is either CloseBidPrice or CloseAskPrice based on tabType
    const newPremium = price * 100 * newNoOfContractsInt;
    const newPremiumPercentage = (newPremium / (shares * price)) * 100; // Assuming price here is the same used for premium calculation

    // Update the state with the new number of contracts and the recalculated premium
    const newFiltersForTicker = {
      ...localFilters[ticker],
      noOfContracts: newNoOfContractsInt,
      premium: newPremium,
      premiumPercentage: newPremiumPercentage
    };

    dispatch(setLocalFilters({ [ticker]: newFiltersForTicker }, tabType));
  }

  function ColumnSelector({ onSelect, isVisible, toggleVisibility }) {
    const menuItemStyle = {
      cursor: 'pointer',
      padding: '8px',
      borderRadius: '4px'
    };

    const handleMouseEnter = (event) => {
      event.target.style.backgroundColor = '#f0f0f0'; // Light background on hover
      event.target.style.color = '#60034C'; // Change text color on hover
    };

    const handleMouseLeave = (event) => {
      event.target.style.backgroundColor = '#fff'; // Back to normal background
      event.target.style.color = 'black'; // Back to normal text color
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (isVisible && !event.target.closest('.dropdown-content')) {
          toggleVisibility(false);
        }
      };

      if (isVisible) {
        document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isVisible, toggleVisibility]);

    const handleItemClick = (columnType) => {
      onSelect(columnType);
      toggleVisibility(false);
    };

    const dropdownStyle = {
      display: isVisible ? 'block' : 'none',
      position: 'absolute',
      zIndex: 100,
      backgroundColor: '#fff',
      boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
      padding: '12px',
      borderRadius: '4px',
      width: 'max-content'
    };

    return (
      <div className="dropdown-content" style={dropdownStyle}>
        <div style={menuItemStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleItemClick('Bid')}>Bid</div>
        <div style={menuItemStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleItemClick('Ask')}>Ask</div>
        <div style={menuItemStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleItemClick('Bid/Ask')}>Bid/Ask</div>
      </div>
    );
  }

  const handleAddToShoppingCart = (card) => {
    dispatch(showNotification(`${card === 'CallWriting' ? 'Covered Call' : 'Individual Put'} Added To Trade Preview`, "success", "toaster"));
    setVisibleCartItems(prevState => ({
      ...prevState,
      [card]: !prevState[card]
    }));
    setShoppingFlag(true);
  }

  const handleCloseOption = (ticker, description) => {
    const holdings = recommendationMode ? filteredStockHoldings[ticker] : existing_option_holdings[ticker];

    if (!holdings || !Array.isArray(holdings)) {
      dispatch(showNotification('No contracts found for the selected ticker', 'info', 'toaster'));
      return;
    }

    set_existing_option_holdings((prevData) => {
      const updatedData = { ...prevData };
      const holdings = updatedData[ticker];

      // Find the contract to close based on description
      const contractIndex = holdings.findIndex(contract => contract.description === description);
      console.log("contractIndex", contractIndex)

      if (contractIndex === -1) {
        console.warn('No contract found with the specified description:', description);
        dispatch(showNotification('No contract found for the selected ticker and description', 'info', 'toaster'));
        return updatedData;
      }

      const contract = holdings[contractIndex];
      const { rollId } = contract;
      console.log(" roll id", rollId)

      if (contract.status === 'Pending Open' && rollId) {
        // Remove the "Pending Open" contract
        holdings.splice(contractIndex, 1);

        // Find the related "Pending Close" contract using the same rollId
        const pendingCloseIndex = holdings.findIndex((c) => c.status === 'Pending Close' && c.rollId === rollId);

        if (pendingCloseIndex !== -1) {
          console.log('Related Pending Close contract found, reverting it to OPEN:', holdings[pendingCloseIndex]);
          // Revert the "Pending Close" contract back to its original state
          holdings[pendingCloseIndex] = {
            ...holdings[pendingCloseIndex].originalContract,
            status: 'OPEN',
          };
          delete holdings[pendingCloseIndex].originalContract; // Remove the reference to original contract
          delete holdings[pendingCloseIndex].rollId; // Remove rollId as it's no longer needed
        } else {
          console.warn('No related Pending Close contract found to revert');
        }

        dispatch(showNotification(`${ticker} Pending Open contract removed and related Pending Close reverted`, 'success', 'toaster'));
      }
      else if (contract.status === 'Pending Close' && contract.originalContract) {
        // Revert the "Pending Close" contract to OPEN
        holdings[contractIndex] = {
          ...contract.originalContract,
          status: 'OPEN',
        };
        delete holdings[contractIndex].originalContract;
        delete holdings[contractIndex].rollId;

        // Find and remove the corresponding "Pending Open" contract using the same rollId
        const pendingOpenIndex = holdings.findIndex((c) => c.status === 'Pending Open' && c.rollId === rollId);

        if (pendingOpenIndex !== -1) {
          console.log('Related Pending Open contract found, removing it:', holdings[pendingOpenIndex]);
          holdings.splice(pendingOpenIndex, 1);
        } else {
          console.warn('No related Pending Open contract found to remove');
        }

        dispatch(showNotification(`${ticker} Pending Close reverted and related Pending Open removed`, 'success', 'toaster'));
      }
      else if (contract.status === 'Pending Close' && !rollId) {
        // Case: Closing an independent Pending Close contract without a rollId

        console.log("Independent Pending Close contract found, reverting to OPEN");

        holdings[contractIndex].status = 'Open';
        dispatch(showNotification(`${ticker} contract reverted to OPEN`, 'success', 'toaster'));
      }
      else if (contract.status === 'Pending Open') {
        set_existing_option_holdings((prevData) => {
          const updatedData = { ...prevData };
          updatedData[ticker].splice(contractIndex, 1);
          persistExistingOptionHoldings(updatedData, tabType);
          updateMaxContracts(ticker);
          dispatch(showNotification(`${ticker} Pending Open contract removed`, 'success', 'toaster'));
          return updatedData;
        });

      }
      else {
        console.warn(`Contract ${description} for ticker ${ticker} is not in a closable state`);
      }

      persistExistingOptionHoldings(updatedData, tabType);
      updateMaxContracts(ticker);
      return updatedData;
    });
  };


  const handleOpenOption = (ticker, bidPrice, askPrice, expirationDate, strike, numContracts, contractType) => {
    const maxContracts = contractQuantities[ticker] || 0;

    console.log("num contracts", numContracts, maxContracts)
    if (maxContracts === 0) {
      dispatch(showNotification(`Cannot open new contract for ${ticker}: zero quantity.`, 'error', 'toaster'));
      return;
    }

    const adjustedNumContracts = contractType === 'C' ? -numContracts : numContracts;
    const formattedStrike = parseFloat(strike).toFixed(2);
    const description = `${ticker}${expirationDate}${contractType}${formattedStrike}`;

    const newContract = {
      stockTicker: ticker,
      expiration_date: expirationDate,
      strike: formattedStrike,
      num_contracts: adjustedNumContracts,
      contractType: contractType,
      description: description,
      bid_price: bidPrice || 0,
      ask_price: askPrice || 0,
      totalValue: (bidPrice || 0) * 100 * Math.abs(numContracts),
      type: contractType,
      contractExpired: false,
      status: 'Pending Open',
    };

    console.log("newContract", newContract)
    // Update the existing_option_holdings state
    set_existing_option_holdings((prevData) => {
      const updatedData = { ...prevData };
      if (!updatedData[ticker]) {
        updatedData[ticker] = [];
      }
      updatedData[ticker].push(newContract);
      persistExistingOptionHoldings(updatedData, tabType);
      return updatedData;
    });

    updateMaxContracts(ticker, numContracts);
    dispatch(showNotification(`${ticker} contract opened successfully with ${adjustedNumContracts} contracts`, 'success', 'toaster'));
  };


  const handleRollClick = (ticker, optionDescription, numContracts, expirationDate, strike, bidPrice) => {
    console.log("expieat", expirationDate)
    const validExpirationDate = expirationDate && moment.utc(expirationDate).isValid()
      ? moment.utc(expirationDate).toISOString()
      : null; // Ensure invalid dates are not stored
    const newSelectedRow = {
      ...selectedRowPerTicker,
      [ticker]: {
        option_description: optionDescription,
        num_contracts: numContracts,
        expiration_date: validExpirationDate,
        strike: strike,
        bid_price: bidPrice,
      },
    };
    console.log("in roll", selectedRowPerTicker[ticker]?.option_description, optionDescription)
    if (
      selectedRowPerTicker[ticker]?.option_description === optionDescription
    ) {
      console.log(" inside if")
      const newState = { ...selectedRowPerTicker };
      delete newState[ticker];
      setSelectedRowPerTicker(newState);
      sessionStorage.setItem('selectedRowPerTicker', JSON.stringify(newState));
    } else {
      // Otherwise, set the new selected row for the ticker
      setSelectedRowPerTicker(newSelectedRow);
      sessionStorage.setItem('selectedRowPerTicker', JSON.stringify(newSelectedRow));
    }
  };


  const handleConfirmRoll = (ticker, key, description, numContracts, expirationDate, strike, bidPrice) => {
    set_existing_option_holdings((prevData) => {
      const updatedData = { ...prevData };
      const holdings = updatedData[ticker];

      if (!holdings || !holdings[key] || holdings[key].description !== description) {
        console.warn("Unable to confirm roll: Contract not found or description mismatch");
        return updatedData;
      }

      // Generate a unique roll ID for the pair
      const rollId = uuidv4();

      // Mark the existing contract as "Pending Close" with the rollId and store original contract details
      holdings[key] = {
        ...holdings[key],
        status: 'Pending Close',
        rollId, // Assign roll ID
        originalContract: { ...holdings[key] },
      };

      // Add a new "Pending Open" contract with the same rollId
      const newRolledContract = {
        stockTicker: ticker,
        expiration_date: expirationDate,
        strike: parseFloat(strike).toFixed(2),
        num_contracts: numContracts,
        contractType: description.includes('C') ? 'C' : 'P',
        description: `${ticker}${expirationDate}${description.includes('C') ? 'C' : 'P'}${parseFloat(strike).toFixed(2)}`,
        bid_price: bidPrice || 0,
        totalValue: (bidPrice || 0) * 100 * Math.abs(numContracts),
        type: description.includes('C') ? 'C' : 'P',
        contractExpired: false,
        status: 'Pending Open',
        isHedgeable: true,
        rollId, // Assign the same roll ID to link it to the Pending Close
      };

      // Insert the new contract as "Pending Open" directly after the "Pending Close" contract
      holdings.splice(key + 1, 0, newRolledContract);

      persistExistingOptionHoldings(updatedData, tabType);
      return updatedData;
    });
    setSelectedRowPerTicker(prevState => {
      const newState = { ...prevState };
      delete newState[ticker];
      sessionStorage.setItem('selectedRowPerTicker', JSON.stringify(newState));
      return newState;
    });
    dispatch(showNotification(`${ticker} roll confirmed: new contract added as Pending Open`, 'success', 'toaster'));
  };

  const handleCancelOption = (ticker, contractDescription) => {
    if (selectedRowPerTicker[ticker]) {
      // Revoke roll selection
      setSelectedRowPerTicker((prevState) => {
        const newState = { ...prevState };
        delete newState[ticker];
        sessionStorage.setItem('selectedRowPerTicker', JSON.stringify(newState));
        return newState;
      });
    } else {
      set_existing_option_holdings((prevData) => {
        const updatedData = { ...prevData };
        const holdings = updatedData[ticker];

        if (holdings) {
          // Find contract by description
          const contractIndex = holdings.findIndex(contract => contract.description === contractDescription);

          if (contractIndex !== -1) {
            const contract = holdings[contractIndex];

            // If not already pending close, save original state before marking as 'Pending Close'
            if (contract.status !== 'Pending Close') {
              holdings[contractIndex] = {
                ...contract,
                status: 'Pending Close',
                originalContract: { ...contract }, // Save original contract details
              };
              persistExistingOptionHoldings(updatedData, tabType);
              updateMaxContracts(ticker); // Update max contracts as needed
              dispatch(showNotification(`${ticker} contract marked as Pending Close`, 'success', 'toaster'));
            }
          } else {
            console.warn(`Contract with description ${contractDescription} not found for ticker ${ticker}`);
          }
        }
        return updatedData;
      });
    }
  };


  const handleRecommendationSelect = (ticker, contract, index) => {
    const isSelected = !selectedItems[ticker];
    dispatch(toggleItemSelection(ticker, isSelected, tabType));

    setSelectedRecommendations((prevState) => {
      const newState = { ...prevState };

      if (newState[ticker]?.includes(index)) {
        console.log(`Deselecting contract for ${ticker} at index ${index}`);
        newState[ticker] = newState[ticker].filter((idx) => idx !== index);
        if (newState[ticker].length === 0) delete newState[ticker];

        set_existing_option_holdings((prevHoldings) => {
          const updatedHoldings = { ...prevHoldings };

          if (updatedHoldings[ticker]) {
            updatedHoldings[ticker] = updatedHoldings[ticker].filter(
              (holding) => !(
                holding.description === contract.description &&
                holding.expiration_date === contract.expiration_date &&
                holding.strike === contract.strike
              )
            );
            if (updatedHoldings[ticker].length === 0) delete updatedHoldings[ticker];
          }

          persistExistingOptionHoldings(updatedHoldings, tabType);
          return updatedHoldings;
        });
      } else {
        if (!newState[ticker]) {
          newState[ticker] = [];
        }
        newState[ticker].push(index);

        set_existing_option_holdings((prevHoldings) => {
          const updatedHoldings = { ...prevHoldings };

          if (!updatedHoldings[ticker]) {
            updatedHoldings[ticker] = [];
          }

          const contractExists = updatedHoldings[ticker].some(
            (existingContract) =>
              existingContract.description === contract.description &&
              existingContract.expiration_date === contract.expiration_date &&
              existingContract.strike === contract.strike
          );

          if (!contractExists) {
            updatedHoldings[ticker].push(contract);
            persistExistingOptionHoldings(updatedHoldings, tabType);
          }

          return updatedHoldings;
        });
      }

      return newState;
    });
  };

  const handleRemoveOptimizedEntry = (ticker) => {
    setOptimizerData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[ticker];
      sessionStorage.setItem('optimizerData', JSON.stringify(updatedData));
      return updatedData;
    });
    setOptimizedPremium((prev) => prev - optimizerData[ticker].bestOption.CloseBidPrice * optimizerData[ticker].optimal_contracts * 100); // Update total premium
  };

  const getMaxContractsAllowed = (totalShares, existingContracts) => {
    console.log(" totoa", totalShares, existingContracts, Math.floor((Math.abs(totalShares) - existingContracts) / 100))
    return Math.floor((Math.abs(totalShares) - existingContracts) / 100);
  };

  const updateMaxContracts = (ticker) => {
    const portfolioEntry = TransfomedData[ticker];
    if (!portfolioEntry) return;

    const totalShares = portfolioEntry.quantity;
    const maxContracts = Math.floor(totalShares / 100); // Calculate max contracts based on total shares

    // Calculate active and pending contracts across both CallWriting and PutBuying
    const activeContracts = (existing_option_holdings[ticker] || []).reduce((sum, contract) => {
      if (
        !contract.contractExpired &&
        (contract.status === 'Open' || contract.status === 'Pending Open' || contract.status === 'Pending Close')
      ) {
        return sum + Math.abs(contract.num_contracts);
      }
      return sum;
    }, 0);

    // Calculate remaining contracts by subtracting active contracts from max contracts
    const remainingContracts = Math.max(maxContracts - activeContracts, 0);

    // Update contractQuantities with the single max contracts limit across both tabs
    setContractQuantities((prev) => ({
      ...prev,
      [ticker]: remainingContracts, // Store remaining contracts that can be opened for this ticker
    }));
  };

  return (
    <>
      {!isEmpty ? (
        <>
          {(!showDashboardSection) ? (
            <>
              {(!shoppingFlag) ? (
                <div className="dashboard-sec">
                  <div className="dashboard-inner">
                    {Object.keys(FilteredData).length === 0 ? (
                      <div className="no-data">
                        <p>Options Data not available to display</p>
                      </div>
                    ) : (
                      <>
                        {(!recommendationMode) &&
                          <>
                            <div className="dashboard-top">
                              <ul className="dash-list p0">
                                <li>
                                  <div className='switcher'>
                                    <span>{tabType === 'CallWriting' ? 'Cap Level' : 'Protection Level'}</span>
                                    <div className="toggle-switch">
                                      <input
                                        type="checkbox"
                                        id="mode-toggle"
                                        checked={useGlobalStdDev}
                                        onChange={() => setUseGlobalStdDev(!useGlobalStdDev)}
                                      />
                                      <label htmlFor="mode-toggle" className="toggle-label"></label>
                                    </div>
                                    <span>Std Dev</span>
                                  </div>
                                </li>
                                <li>
                                  <div className='ai-form-group'>
                                    <label>{useGlobalStdDev ? 'Std Dev Level' : tabType === 'CallWriting' ? 'Global Cap Level' : 'Protection Level'}</label>
                                    {useGlobalStdDev ? (
                                      <select
                                        value={globalStdDevLevel}
                                        className='ai-form-value small'
                                        onChange={(e) => {
                                          handleGlobalFilterChange(null, null, parseFloat(e.target.value).toFixed(1))
                                        }}
                                      >
                                        {Array.from({ length: 31 }, (_, i) => (i / 10).toFixed(1)).map(value => (
                                          <option key={value} value={value}>{value}</option>
                                        ))}
                                      </select>
                                    ) : (
                                      <input
                                        type="text"
                                        className='ai-form-value small'
                                        value={GlobalCapLevel}
                                        onChange={(e) => handleGlobalFilterChange(e.target.value, null)}
                                      />
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <label>
                                    Exp Date
                                    <CustomDatePicker
                                      defaultDate={ExpDate ? ExpDate : null}
                                      onDateSelect={(date) => handleGlobalFilterChange(null,  date, null)}
                                    />
                                    {/* <DatePicker
                                      selected={moment.utc(ExpDate).toDate()}
                                      onChange={(date) => handleGlobalFilterChange(null, moment.utc(date).startOf('day').format('YYYY-MM-DD'))}
                                      filterDate={isFriday}
                                      dateFormat="yyyy-MM-dd"
                                      customInput={<CustomDateInput />}
                                      showIcon={false}
                                    /> */}
                                  </label>
                                </li>
                                <li>
                                  {tabType === 'CallWriting' && (
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px' }}>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {(subscriptionsDetails.user_type === 'Advisor' && ((subscriptionsDetails.subscription_plan_type === 'Adaptive Standard Plan' && subscriptionsDetails.active_trial_period) || ['Adaptive Advisor Pro Plan', 'Whitelist Customer Plan'].includes(subscriptionsDetails.subscription_plan_type))) && (
                                          <button
                                            className="ai-btn primary solid optimizer-btn"
                                            onClick={() => handleOptimizerClick()}
                                            disabled={isOptimizerLoading}
                                          >
                                            {isOptimizerLoading ? 'Optimizing...' : 'Optimize'}
                                          </button>
                                        )}
                                        <span className="optimizer-beta-v" title="Please select less than 7 symbols include for optimization.">(Beta)</span>
                                        {isOptimizerLoading && (
                                          <span style={{ marginLeft: '10px' }}>
                                            <Loader size='small' />
                                          </span>
                                        )}
                                      </div>
                                      <p style={{ margin: 'px 0 0 0' }}>(to match shield cost)</p>
                                    </div>
                                  )}
                                </li>

                              </ul>
                            </div>

                            <div className="dashboard-top">
                              <ul className="dash-list p0">
                                <li><h4>List of Symbols</h4></li>
                                <li>
                                  <p>Portfolio Holdings: <span className="txt-clr">{portfolioDetails.PortfolioLineItem.Hedgeable.length + portfolioDetails.PortfolioLineItem.Unhedgeable.length}</span></p>
                                </li>
                                <li>
                                  <p>{tabType === 'CallWriting' ? "Available to write Calls:" : "Available to write Puts:"} <span className="txt-clr">{Object.keys(FilteredData).length}</span></p>
                                </li>
                                <li>
                                  <p>Portfolio Value: <span className="txt-clr">${totalPortfolioValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                </li>
                                <li>
                                  <p>{tabType === 'CallWriting' ? "Premium Total:" : "Cost Total:"} <span className="txt-clr">${totalPremium.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ({(totalPremium / totalPortfolioValue * 100).toFixed(2)}%)</span></p>
                                </li>
                                {tabType === 'CallWriting' && <li>
                                  <p>Optimized Premium: <span className="txt-clr">
                                    ${Number(optimizedPremium).toFixed(2).toLocaleString()}
                                    {/* ${optimizedPremium.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                                  </span></p>
                                </li>}
                              </ul>
                            </div>
                          </>
                        }
                        <div className="dashboard-table">
                          <div className="table-responsive">
                            <table className="table table-striped callwriting">
                              <thead>
                                <tr>
                                  <th className="checkbox-column">
                                    {recommendationMode ? (
                                      <input
                                        type="checkbox"
                                        checked={
                                          filteredTickers.length > 0 &&
                                          filteredTickers.every(ticker =>
                                            selectedRecommendations[ticker] &&
                                            selectedRecommendations[ticker].length === (filteredStockHoldings[ticker] || []).length
                                          )
                                        }
                                        onChange={handleSelectAllChange}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        checked={allSelected}
                                        onChange={handleSelectAllChange}
                                      />
                                    )}
                                  </th>

                                  <th>SYMBOL <img src={`${process.env.PUBLIC_URL}/Arrow-icon.svg`} alt="Arrow-icon" /></th>
                                  {/* <th>SHARES <img src={`${process.env.PUBLIC_URL}/Arrow-icon.svg`} alt="Arrow-icon" /></th> */}
                                  <th>Quantity</th>
                                  <th>Action</th>
                                  <th>
                                    {
                                      recommendationMode
                                        ? 'Protection Level'
                                        : (useGlobalStdDev ? 'Std Dev Level' : (tabType === 'CallWriting' ? 'Cap Level' : 'Protection Level'))
                                    }
                                    <img src={`${process.env.PUBLIC_URL}/Arrow-icon.svg`} alt="Arrow-icon" />
                                  </th>
                                  <th>Exp date <img src={`${process.env.PUBLIC_URL}/Arrow-icon.svg`} alt="Arrow-icon" /></th>
                                  <th className="hoverable-selector" style={headingStyle} onMouseOver={() => setDropdownVisible(true)}>
                                    <span className="selector">{priceToShow} <img src="Assets/c_down.svg" alt="toggle" /></span>
                                    <ColumnSelector
                                      onSelect={changePriceToShow}
                                      isVisible={dropdownVisible}
                                      toggleVisibility={setDropdownVisible}
                                    />
                                  </th>
                                  <th>Strike Price</th>
                                  <th>{tabType === 'CallWriting' ? 'PREMIUM $' : 'COST $'}</th>
                                  <th>% Stock Value</th>
                                  <th>% Portfolio Value</th>
                                  <th></th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  Object.entries(FilteredData)
                                    .filter(([ticker]) => !recommendationMode || filteredTickers.includes(ticker))
                                    .map(([ticker, [bestOption]]) => {
                                      const portfolioEntry = TransfomedData[ticker];
                                      const optimizerEntry = optimizerData[ticker];

                                      if (bestOption && portfolioEntry) {
                                        const shares = portfolioEntry.quantity;
                                        const noOfContracts = Math.floor(shares / 100);
                                        let userEnteredContracts = localFilters[ticker]?.noOfContracts || 0;
                                        userEnteredContracts = Math.max(1, Math.min(userEnteredContracts, noOfContracts));
                                        let premium = 0;
                                        premium = tabType === 'CallWriting'
                                          ? parseFloat(bestOption.CloseBidPrice) * 100 * userEnteredContracts
                                          : parseFloat(bestOption.CloseAskPrice) * 100 * userEnteredContracts;
                                        const premiumPercentage = (premium / (shares * bestOption.ClosePrice)) * 100;
                                        const portfolioPercentage = (premium / totalPortfolioValue) * 100;
                                        const expirationDate = bestOption.ExpirationDate
                                          ? formatDateToYYYYMMDD(parseDate(bestOption.ExpirationDate))
                                          : "";
                                        const hasExisitingHolding = ticker in existing_option_holdings;
                                        const isCollapsed = collapseStates[ticker] || false;
                                        const openContracts = hasExisitingHolding
                                          ? existing_option_holdings[ticker].filter(holding => !holding.contractExpired && holding.status !== 'Pending Close')
                                          : [];

                                        const NumberOfExistingContracts = openContracts.length;
                                        // const canOpenMoreContracts = NumberOfExistingContracts < noOfContracts;
                                        // Add existing holdings rows first, if any
                                        const existingRows = [];
                                        const totalShares = portfolioEntry.quantity;

                                        // Calculate existing and pending open contracts
                                        const existingContracts = openContracts.reduce(
                                          (sum, contract) => sum + Math.abs(contract.num_contracts), 0
                                        );

                                        // Determine the maximum contracts allowed, considering existing contracts
                                        const maxContracts = getMaxContractsAllowed(totalShares, existingContracts);

                                        // Set the default quantity if not already set in the state
                                        const currentContracts = contractQuantities[ticker] !== undefined
                                          ? contractQuantities[ticker]
                                          : maxContracts;

                                        // Check if more contracts can be opened
                                        const canOpenMoreContracts = (maxContracts > 0);
                                        let holdingsToDisplay = recommendationMode ? filteredStockHoldings[ticker] : (existing_option_holdings[ticker] || []);
                                        { console.log("holdingsToDisplay", holdingsToDisplay, existing_option_holdings[ticker], filteredStockHoldings[ticker]) }
                                        // if (hasExisitingHolding && !isCollapsed) {
                                        if (holdingsToDisplay && holdingsToDisplay.length > 0 && !isCollapsed) {
                                          holdingsToDisplay.filter(value =>
                                            !value.contractExpired &&
                                            (
                                              (tabType === 'CallWriting' && value.type === 'C') ||
                                              (tabType === 'PutBuying' && value.type === 'P')
                                            )
                                          ).forEach((value, key) => {
                                            existingRows.push(
                                              <React.Fragment key={`${ticker}-${key}`}>
                                                <tr
                                                  key={ticker + key}
                                                  onClick={(e) => handleRowClick(e, ticker, value.description, value.num_contracts, value.expiration_date, value.strike, value.bid_price, bestOption.ClosePrice, key)}
                                                  className={selectedRowPerTicker[ticker]?.option_description === value.description ? 'selected-row' : 'contract-row'}
                                                >
                                                  {recommendationMode ? (
                                                    <td>
                                                      <input
                                                        type="checkbox"
                                                        checked={selectedRecommendations[ticker]?.includes(key) || false}
                                                        onChange={() => handleRecommendationSelect(ticker, value, key)}
                                                      />
                                                    </td>
                                                  ) : (
                                                    <td className="checkbox-column"></td>
                                                  )}
                                                  <td>{value.description}</td>
                                                  <td>{tabType === 'CallWriting' ? -Math.abs(value.num_contracts) : value.num_contracts}</td>
                                                  {/* {value.num_contracts}</td> */}
                                                  <td style={{
                                                    color: value.status === 'Pending Open' ? '#FFA500' :
                                                      value.status === 'Pending Roll' ? '#FFA500' :
                                                        value.status === 'Pending Close' ? 'red' :
                                                          value.status ? '#38BB55' : '#38BB55'
                                                  }}
                                                  >
                                                    {value.status === 'Pending Open' ? 'Pending Open' :
                                                      value.status === 'Pending Roll' ? 'Pending Roll' :
                                                        value.status === 'Pending Close' ? 'Pending Close' :
                                                          value.status ? 'Open' : 'OPEN'}
                                                  </td>
                                                  <td>
                                                    {recommendationMode ? `${protectionLevel}%` : ''}
                                                  </td>
                                                  <td>
                                                    {value.expiration_date}
                                                  </td>
                                                  {recommendationMode ? (
                                                    <>
                                                      <td>
                                                        {Number(parseFloat(value.bid_price).toFixed(2))}
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <td></td>
                                                    </>
                                                  )}
                                                  <td>${value.strike}</td>
                                                  {recommendationMode ? (
                                                    <>
                                                      <td>
                                                        {Number(parseFloat(value.bid_price * value.num_contracts * 100).toFixed(2))}
                                                      </td>
                                                      <td>
                                                        {(Number(parseFloat(value.bid_price * value.num_contracts * 100).toFixed(2)) / (shares * bestOption.ClosePrice) * 100).toFixed(3)}%
                                                      </td>
                                                      <td>
                                                        {(Number(parseFloat(value.bid_price * value.num_contracts * 100).toFixed(2)) / totalPortfolioValue * 100).toFixed(3)}%
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <td></td>
                                                      <td></td>
                                                      <td></td>
                                                    </>
                                                  )}
                                                  <td>

                                                    <div class="button-container">
                                                      {(value.status !== 'Pending Close' && value.status !== 'Pending Open' && value.status !== 'CLOSE') && (
                                                        <>
                                                          <button
                                                            className="icon-button roll"
                                                            onClick={() => handleRollClick(ticker, key, value.description, value.num_contracts, value.expiration_date, value.strike, value.bid_price, bestOption.ClosePrice)}
                                                            title="Roll"
                                                          >
                                                            <img
                                                              src={`${process.env.PUBLIC_URL}/Assets/reload_icon.svg`}
                                                              alt="Roll"
                                                              className="button-icon"
                                                            />
                                                          </button>
                                                          <button
                                                            className="icon-button cancel"
                                                            onClick={() => handleCancelOption(ticker, value.description)}
                                                            title="Cancel"
                                                          >
                                                            <img
                                                              src={`${process.env.PUBLIC_URL}/Assets/close_icon.svg`}
                                                              alt="Cancel"
                                                              className="button-icon"
                                                            />
                                                          </button>
                                                        </>
                                                      )}
                                                      {((value.status === 'Pending Open' || value.status === 'Pending Close') && !recommendationMode) && (
                                                        <button
                                                          className="icon-button close"
                                                          onClick={() => handleCloseOption(ticker, value.description, key)}
                                                          title="Cancel"
                                                        >
                                                          <img
                                                            src={`${process.env.PUBLIC_URL}/Assets/close_icon.svg`}
                                                            alt="Cancel"
                                                            className="button-icon"
                                                          />
                                                        </button>
                                                      )}

                                                    </div>
                                                  </td>
                                                </tr>
                                                {console.log("selectedRowPerTicker", selectedRowPerTicker, contractQuantities)}
                                                {console.log("contractQuantities", contractQuantities)}
                                                {selectedRowPerTicker[ticker]?.option_description === value.description && (
                                                  (<tr key={ticker} className={`${hasExisitingHolding ? 'opentiker' : 'collapsed'} ${selectedRowPerTicker[ticker] ? 'selected' : ''}`}>
                                                    <td>{key}</td>
                                                    <td>{ticker}</td>
                                                    <td>
                                                      {
                                                        selectedRowPerTicker[ticker].num_contracts
                                                      }
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                      {useGlobalStdDev ? (
                                                        <select
                                                          value={localFilters[ticker]?.stdDevLevel || globalStdDevLevel}
                                                          onChange={(e) => handleLocalFilterChange(ticker, null, null, parseFloat(e.target.value).toFixed(1))}
                                                        >
                                                          {Array.from({ length: 31 }, (_, i) => (i / 10).toFixed(1)).map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                          ))}
                                                        </select>
                                                      ) : (
                                                        <input
                                                          type="text"
                                                          value={localFilters[ticker]?.capLevel || GlobalCapLevel}
                                                          onChange={(e) => handleLocalFilterChange(ticker, e.target.value, null)}
                                                        />
                                                      )}
                                                    </td>
                                                    <td className='smalldate'>
                                                      {/* <FaCalendarAlt/> */}
                                                      <CustomDatePicker
                                                        defaultDate={expirationDate ? expirationDate : null}
                                                        onDateSelect={(date) => handleLocalFilterChange(ticker, null, date)}
                                                      />
                                                      {/* <DatePicker
                                                        showIcon={false}
                                                        selected={expirationDate ? moment.utc(expirationDate).toDate() : null}
                                                        onChange={(date) => handleLocalFilterChange(ticker, null, date)}
                                                        filterDate={isFriday}
                                                        customInput={<CustomDateInput />}
                                                        dateFormat="yyyy-MM-dd"
                                                      /> */}
                                                    </td>
                                                    <td>
                                                      {priceToShow === 'Bid' ? parseFloat(bestOption.CloseBidPrice).toFixed(2) :
                                                        priceToShow === 'Ask' ? parseFloat(bestOption.CloseAskPrice).toFixed(2) :
                                                          `${parseFloat(bestOption.CloseBidPrice).toFixed(2)}/${parseFloat(bestOption.CloseAskPrice).toFixed(2)}`
                                                      }
                                                    </td>
                                                    <td>{Number(addZeroes(Number(parseFloat(bestOption.Strike).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                                                    <td>{Number(addZeroes(Number(parseFloat(premium).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                                                    <td>{premiumPercentage.toFixed(3)}%</td>
                                                    <td>{portfolioPercentage.toFixed(3)}%</td>
                                                    <td>
                                                      {value.status !== 'Pending Close' && (
                                                        <>
                                                          <div class="button-container">
                                                            <button
                                                              className="OptionButton"
                                                              onClick={() => handleConfirmRoll(ticker, key, value.description, value.num_contracts, expirationDate, bestOption.Strike, bestOption.CloseBidPrice, bestOption.CloseAskPrice, bestOption.ClosePrice)}
                                                              title="Roll"
                                                            >
                                                              Confirm Roll
                                                            </button>

                                                            <button className="OptionButton" onClick={() => GoToOptionChainScreen(ticker)}>
                                                              Option Chain
                                                            </button>
                                                          </div>
                                                        </>
                                                      )}
                                                    </td>
                                                  </tr>)
                                                )}
                                              </React.Fragment>
                                            );
                                          });
                                        }
                                        const optimizerRow = (optimizerEntry && tabType === 'CallWriting') ? (
                                          <tr key={`${ticker}-optimizer`} className="optimizer-row">
                                            <td></td>
                                            <td>{ticker} (Optimized)</td>
                                            <td>- {optimizerEntry.optimal_contracts}</td>
                                            <td style={{ color: 'orange' }}>Pending Open</td>
                                            <td>{optimizerEntry.PercentofClose.toFixed(2)}%</td>
                                            <td>{ExpDate}</td>
                                            <td>{optimizerEntry.bestOption ? optimizerEntry.bestOption.CloseBidPrice : ''}</td>
                                            <td>${optimizerEntry.optimal_strike_price}</td>
                                            <td>${(optimizerEntry.optimal_contracts * (optimizerEntry.bestOption ? optimizerEntry.bestOption.CloseBidPrice : 0) * 100).toFixed(2)}</td>
                                            <td>{((optimizerEntry.optimal_contracts * (optimizerEntry.bestOption ? optimizerEntry.bestOption.CloseBidPrice : 0) * 100) / totalPortfolioValue * 100).toFixed(2)}%</td>
                                            <td></td>
                                            <td>
                                              <button
                                                className="icon-button close"
                                                onClick={() => handleRemoveOptimizedEntry(ticker)}
                                                title="Remove Optimized Entry"
                                              >
                                                <img src={`${process.env.PUBLIC_URL}/Assets/close_icon.svg`} alt="Close" className="button-icon" />
                                              </button>
                                            </td>
                                          </tr>
                                        ) : null;

                                        const controlRow = (!recommendationMode) ? (
                                          <tr key={ticker} className={`${hasExisitingHolding ? 'opentiker' : 'collapsed'}`}>
                                            {hasExisitingHolding && (
                                              <td>
                                                <button className='icon-button small collapse' onClick={() => toggleCollapse(ticker)}>
                                                  {isCollapsed ? '+' : '-'}
                                                </button>
                                              </td>
                                            )}
                                            {!hasExisitingHolding && <td></td>}
                                            <td>{ticker}</td>
                                            <td>
                                              {(() => {
                                                return (
                                                  <input
                                                    type="number"
                                                    min="0"
                                                    max={maxContracts}
                                                    value={currentContracts}
                                                    onChange={(e) => {
                                                      const newQuantity = parseInt(e.target.value, 10) || 0;

                                                      const adjustedQuantity = Math.min(newQuantity, maxContracts);
                                                      setContractQuantities({
                                                        ...contractQuantities,
                                                        [ticker]: adjustedQuantity,
                                                      });
                                                      handleContractChange(
                                                        ticker,
                                                        adjustedQuantity,
                                                        totalShares,
                                                        tabType === 'CallWriting' ? bestOption.CloseBidPrice : bestOption.CloseAskPrice
                                                      );
                                                    }}
                                                    disabled={!canOpenMoreContracts}
                                                  />
                                                );
                                              })()}
                                            </td>
                                            <td></td>
                                            <td>
                                              {useGlobalStdDev ? (
                                                <select
                                                  value={localFilters[ticker]?.stdDevLevel || globalStdDevLevel}
                                                  onChange={(e) => handleLocalFilterChange(ticker, null, null, parseFloat(e.target.value).toFixed(1))}
                                                >
                                                  {Array.from({ length: 31 }, (_, i) => (i / 10).toFixed(1)).map(value => (
                                                    <option key={value} value={value}>{value}</option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <input
                                                  type="text"
                                                  value={localFilters[ticker]?.capLevel || GlobalCapLevel}
                                                  onChange={(e) => handleLocalFilterChange(ticker, e.target.value, null)}
                                                />
                                              )}
                                            </td>
                                            <td className='smalldate'>
                                              {/* <FaCalendarAlt/> */}
                                              <CustomDatePicker
                                                defaultDate={expirationDate ? expirationDate : null}
                                                onDateSelect={(date) => handleLocalFilterChange(ticker, null, date)}
                                              />
                                              {/* <DatePicker
                                                showIcon={false}
                                                selected={expirationDate ? moment.utc(expirationDate).toDate() : null}
                                                onChange={(date) => handleLocalFilterChange(ticker, null, date)}
                                                filterDate={isFriday}
                                                customInput={<CustomDateInput />}
                                                dateFormat="yyyy-MM-dd"
                                              /> */}
                                            </td>
                                            <td>
                                              {priceToShow === 'Bid' ? parseFloat(bestOption.CloseBidPrice).toFixed(2) :
                                                priceToShow === 'Ask' ? parseFloat(bestOption.CloseAskPrice).toFixed(2) :
                                                  `${parseFloat(bestOption.CloseBidPrice).toFixed(2)}/${parseFloat(bestOption.CloseAskPrice).toFixed(2)}`
                                              }
                                            </td>
                                            <td>{Number(addZeroes(Number(parseFloat(bestOption.Strike).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                                            <td>{Number(addZeroes(Number(parseFloat(premium).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                                            <td>{premiumPercentage.toFixed(3)}%</td>
                                            <td>{portfolioPercentage.toFixed(3)}%</td>
                                            <td>
                                              <div className='button-container'>

                                                <button
                                                  className="icon-button add"
                                                  onClick={() => handleOpenOption(
                                                    ticker,
                                                    parseFloat(bestOption.CloseBidPrice),
                                                    parseFloat(bestOption.CloseAskPrice),
                                                    expirationDate,
                                                    parseFloat(bestOption.Strike),
                                                    contractQuantities[ticker],
                                                    tabType === 'CallWriting' ? 'C' : 'P'
                                                  )}
                                                  title="Open"
                                                >
                                                  <img
                                                    src={`${process.env.PUBLIC_URL}/Assets/add_icon.svg`}
                                                    alt="Open"
                                                    className="button-icon"
                                                  />
                                                </button>

                                                <button className="OptionButton" onClick={() => GoToOptionChainScreen(ticker)}>
                                                  Option Chain
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        ) : (null)

                                        const headerRow = (
                                          <tr key={`${ticker}-header`} className='ticker-header-row'>
                                            <td className="checkbox-column">
                                              {(!recommendationMode) &&
                                                <input
                                                  type="checkbox"
                                                  checked={!!selectedItems[ticker]}
                                                  onChange={() => handleSelectionChange(ticker)}
                                                />}
                                            </td>
                                            <td className="ticker-header-cell">
                                              <strong>{ticker}</strong>
                                            </td>
                                            <td className="ticker-header-cell">
                                              <strong>{shares}</strong>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        );

                                        return [headerRow, ...existingRows, optimizerRow, controlRow]; // First show existing option rows, then control row
                                      }
                                      return null;
                                    })
                                }

                              </tbody>

                            </table>
                          </div>
                        </div>
                        {!recommendationMode &&
                          <>
                            <div className="cart-btn">
                              <a href="javascript:;" className='ai-btn primary line' onClick={(e) => {
                                e.preventDefault();
                                handleAddToShoppingCart(tabType);
                                setShoppingFlag(true);
                              }}>
                                Preview Trades
                              </a>
                            </div>
                          </>}
                      </>
                    )}
                  </div>
                </div>
              ) : (

                <div className="shopping-cart">
                  <button className="ai-btn line secondary"
                    onClick={() => setShoppingFlag(false)}> Back </button>
                  <h3> Trade Preview</h3>

                  <div className="shopping-card-container">
                    {shoppingFlag && <ShoppingCart
                      existing_option_holdings={existing_option_holdings}
                      portfolioId={portfolioId}
                      selectedPrice={priceToShow}
                      visibleCartItems={visibleCartItems} setVisibleCartItems={setVisibleCartItems} />}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <DashboardSection
                portfolioDetails={portfolioDetails}
                tabType={tabType}
                ticker={selectedTicker}
              />
            </>
          )
          }
        </>) : (
        <div className="drawercontent">
          <p>Portfolio is Empty. Please add stock to proceed</p>
        </div>
      )}
    </>
  );
}

export default Dashboard;
