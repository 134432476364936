import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import ArchivePortfolio from '../../Components/ArchivePortfolio/ArchivePortfolio';
import '../../Screens/ADClientSummaryScreen/ADClientSummaryScreen.css';

function ArchivePortfolioScreen() {

    return (
        <>
            <Navbar />
            <div className="container">
                <main className="main-content">
                    <ArchivePortfolio />
                </main>
            </div>
        </>
    );
}

export default ArchivePortfolioScreen;
