import React, { useState } from 'react';
import Loader from '../Loader/Loader';
import "./LoginWindow.scss";
import { useHistory } from "react-router-dom";
import {
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";
import { Establish_user_session } from "../../Apis/establish_user_session";
import { AddInvestorSessionID } from "../../Apis/AddInvestorSessionID";
import TagManager from "react-gtm-module";
import { fetchAvailableTickers } from '../../actions/availableTickersAction';
import { useDispatch,useSelector } from 'react-redux';
import { fetchProfile } from '../../actions/advisorProfileActions';
import { fetchUserPicture } from '../../actions/userPictureActions';
import { fetchUserSubscriptionData } from '../../actions/userSubscriptionActions';
function LoginWindow({ setScreen, handleOTPConfirmation }) {

  const dispatch = useDispatch();
  const history = useHistory();
  const LoggedIn = () => history.push("/ClientPortfolios");
  const [screenRedirect, setScreenRedirect] = React.useState(null);

  const popupRedirect = () => {
    if (screenRedirect !== null) {
      var screen = screenRedirect
      setScreenRedirect(null);
      history.push({
        pathname: screen,
        state: {
          userName: UserName,
          userEmail: "",
        }
      })
    } else {
      setPopupState(false);
    }
  };
  //   backdrop: {
  //     zIndex: theme.zIndex.drawer + 1,
  //     color: "#fff",
  //   },
  // }));
  const advisorProfile = useSelector((state) => state.advisorProfile.profileData)
  const [selectedAcType, ChangeSelectedAcType] = React.useState("");
  const [UserName, setUserName] = React.useState("");
  const [Password, setPassword] = React.useState("");
  const [WrongUsername, setWrongUsername] = React.useState("");
  const [WrongPassword, setWrongPassword] = React.useState("");
  const [bdopen, setBdOpen] = React.useState(false);
  const [Token, setToken] = React.useState("");
  const [loggedInUser, setLoggedInUser] = React.useState("");
  const [sessionID, setSessionID] = React.useState("");
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [showAgreementPopup, setShowAgreementPopup] = React.useState(false);
  const [AgreementFlag, setAgreementFlag] = React.useState(null);
  const [clientId, setClientId] = React.useState(null);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [AgreementText, setAgreementText] = React.useState("");
  const [subscriptionStatus, setSubscriptionStatus] = React.useState("");
  const [PaymentLinks, setPaymentLinks] = React.useState({});
  var verifyInvestorCount = 0;
  var verifyAdvisorCount = 0;

  React.useEffect(() => {
    window.sessionStorage.setItem("Token", Token);
  }, [Token]);

  React.useEffect(() => {
    window.sessionStorage.setItem("clientId", clientId);
  }, [clientId]);

  React.useEffect(() => {
    window.sessionStorage.setItem("sessionID", sessionID);
  }, [sessionID]);

  const handleBdClose = () => {
    setBdOpen(false);
  };
  const handleBdToggle = () => {
    setBdOpen(!bdopen);
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const [isAccnVisible, setIsAccnVisible] = useState(true);
  const toggleaccount = () => {
    setIsAccnVisible(!isAccnVisible);
  };
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const togglelogin = () => {
    setIsLoginVisible(!isLoginVisible);
  };

  const verifyInvestorOTP = (user) => {
    if (verifyInvestorCount === 1) {
      verifyInvestorCount = 0;
      setWrongUsername("")
      setWrongPassword("");
      user.resendConfirmationCode(function (err, result) {
        if (err) {
          console.log(err.message || JSON.stringify(err))
        }
        setPopupState(true);
        setPopupMessage(
          "Please verify your account with the Verification code sent to your registered email address. Please check your inbox."
        );
        var email = user.getUsername();
        handleOTPConfirmation(email);
        setScreen("OTP");
        setAlertType("warning");
        setPopupLoginRedirect(false);
      })
    }
  }

  const verifyAdvisorOTP = (user) => {
    if (verifyAdvisorCount === 1) {
      verifyAdvisorCount = 0;
      setWrongUsername("")
      setWrongPassword("");
      user.resendConfirmationCode(function (err, result) {
        if (err) {
          console.log(err.message || JSON.stringify(err))
        }
        setPopupState(true);
        setScreenRedirect("/OTPConfirmationScreen");
        setPopupMessage(
          "Please verify your account with the Verification code sent to your registered email address. Please check your inbox."
        );
        setAlertType("warning");
        setPopupLoginRedirect(false);
      })
    }
  }

  const validateLogin = () => {
    let validLoginFlag = true
    if (!UserName) {
      validLoginFlag = false;
      setWrongUsername("Email cannot be empty.");
    }
    if (UserName && !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(UserName))) {
      validLoginFlag = false;
      setWrongUsername("Invalid Email.");

    }
    if (!Password) {
      validLoginFlag = false;
      setWrongPassword("Password cannot be empty.");
    }
    return validLoginFlag;
  }

  const LogIn = async (event) => {
    if (validateLogin()) {
      handleBdToggle();
      event.preventDefault();
      const user = new CognitoUser({
        Username: UserName.trim().toLowerCase(),
        Pool: UserPool,
      });
      const authDetails = new AuthenticationDetails({
        Username: UserName.trim().toLowerCase(),
        Password: Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: async (data) => {
          setWrongUsername("");
          setWrongPassword("");
          Establish_user_session(data, selectedAcType);
          const user = UserPool.getCurrentUser();
          if (user === null) {
            console.log("Not Logged In");
          } else {
            var user_name = user.getUsername();
            setLoggedInUser(user_name);
            user.getSession(async function (error, session) {
              if (error) {
                console.log(error);
              }
              if (session) {
                // var session_id = createUserSessionID();
                // setSessionID(session_id);
                setToken(session.idToken.jwtToken);
                user.getUserAttributes(async function (err, result) {
                  if (err) {
                    console.log(err);
                  }
                  console.log(" user", user)
                  AddInvestorSessionID(user_name).then(async (data) => {
                    console.log("data logg", data);
                    setClientId(data['customer_id']);
                    setAgreementText(data['agreement_body']);
                    setSubscriptionStatus(data["subscription_status"])
                    setPaymentLinks(data["payment_links"]);
                    dispatch(fetchProfile());
                    dispatch(fetchUserPicture());
                    sessionStorage.setItem('userType',data['user_type']);
                    sessionStorage.setItem("selectedAcType",data['user_type']);
                    sessionStorage.setItem('subscriptionStatus', data['subscription_status']);
                    sessionStorage.setItem('CustomerID', data['customer_id']);
                    sessionStorage.setItem('PaymentLinks', JSON.stringify(data['payment_links']));
                    sessionStorage.setItem('AgreementText', JSON.stringify(data['agreement_body']));
                    await dispatch(fetchUserSubscriptionData(data));
                    await dispatch(fetchAvailableTickers());
                    console.log("after fetch")
                    if (data['agreement_flag'] === 'False') {
                      setBdOpen(false);
                      LoggedIn();
                      setAgreementFlag(data['agreement_flag']);
                    } else if (data['agreement_flag'] === 'True') {
                      // sleep(100).then(() => {

                      LoggedIn();
                      setAgreementText(data['agreement_body']);
                      // });
                    } else if (data === 0) {
                      setBdOpen(false);
                      setPopupState(true);
                      setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
                      setAlertType("warning");
                      setPopupLoginRedirect(false);
                      return;
                    }
                    else if (data === false) {
                      setBdOpen(false);
                      setPopupState(true);
                      setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
                      setAlertType("warning");
                      setPopupLoginRedirect(false);
                      return;
                    }
                    else if (data === 401) {
                      setBdOpen(false);
                      setPopupState(true);
                      setPopupMessage(
                        "Your session timed out for security reasons. Please sign-in again."
                      );
                      setAlertType("error");
                      setPopupLoginRedirect(true);
                      return;
                    }
                    TagManager.dataLayer({
                      dataLayer: {
                        'event': 'UserLogin',
                        'userType': data['user_type'],
                        'user_id': data['customer_id'],
                      },
                    });
                  });
                });
              }
            });
          }
        },

        onFailure: (err) => {
          handleBdClose();
          for (const [key, value] of Object.entries(err)) {
            if (value === 'UserNotFoundException') {
              setWrongUsername("Email Id does not exist.")
              setWrongPassword("");
            }
            else if (value === 'NotAuthorizedException') {
              setWrongPassword("Incorrect Password.")
              setWrongUsername("");
            }
            else if (value === 'UserNotConfirmedException') {
              if (verifyInvestorCount === 0) {
                verifyInvestorCount = 1;
                sleep(10).then(() => {
                  verifyInvestorOTP(user)
                });

              }
            }
          }
        },

        newPasswordRequired: (data) => {
          handleBdClose();
          console.log("changes password required", data);
        },
      });
    }
  };




  React.useEffect(() => {
    if (clientId && AgreementText && AgreementFlag) {
      setShowAgreementPopup(true);
    }
  }, [clientId, AgreementText, AgreementFlag]);

  const handlePopup = () => {
    setPopupState(false);
  }
  return (
    <>
      {bdopen ? (
        <div className="loading-overlay">
          <Loader/>
        </div>) : (
        <div className="ai-login-section">
          <h2 >Login</h2>
          <div className="ai-field-group">
            <div className="ai-form-group">
              <label>Email</label>
              <input
                id="username"
                className="ai-form-value"
                type="text"
                placeholder="Enter Email Id"
                onChange={(event) => setUserName(event.target.value)}
              />
              <div className="ai-error-message">
                <p>{WrongUsername}</p>
              </div>
            </div>
            <div className="ai-form-group">
              <label>Password</label>
              <input
                id="password"
                className="ai-form-value ai-password"
                type="password"
                placeholder="Password"
                onChange={(event) => setPassword(event.target.value)}
              />
              <div className="ai-error-message">
                <p>{WrongPassword}</p>
              </div>
            </div>
            <div className="ai-forgot-password">
              <a to="/ForgotPassword" onClick={() => setScreen("Forgot Password")}>Forgot Password?</a>
            </div>
          </div>
          <div className="ai-field-group">
            <div className="ai-row v-middle">
              <div className="ai-column">
                <div className="ai-signin">
                  <span>Don’t have an account?</span>
                  <a id='SignUp' className="ai-link" onClick={() => setScreen("Sign Up")}>
                    Sign Up
                  </a>
                </div>
              </div>
              <button className="ai-btn solid primary round" onClick={LogIn}><img src="./Assets/next.svg" /></button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginWindow;