import React, { useState } from 'react';
import { BeatLoader } from 'react-spinners';
import Loader from '../../Components/Loader/Loader';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../actions/drawerActions';
import { portfolioAdded } from '../../actions/portfolioActions';
import { handleApiError , showNotification} from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import TagManager from "react-gtm-module";
import { ProcessCsvFile } from '../../Apis/ProcessCsvFile';
import { AddCsvData } from '../../Apis/AddCsvData';
import './ImportCSVPortfolioDrawer.scss';

function ImportCSVPortfolioDrawer() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [importStage, setImportStage] = React.useState(1);
    const [portfolioName, setPortfolioName] = useState('');
    const [selectedFile, setSelectedFile] = React.useState();
    const [fileError, setFileError] = React.useState(null);
    const drawerData = useSelector(state => state.drawer.data);
    const [csvData, setCsvData] = useState([]);
    const [isHedgeableSelected, setIsHedgeableSelected] = useState(true);
    const [errorMessage , setErrorMessage] = useState("")
    const [expandedPortfolios, setExpandedPortfolios] = useState({});
    const openedFromSidebar = drawerData.openedFromSidebar;
    const customerId = drawerData.customerId;
    const subscriptionsDetails = useSelector(state => state.userSubscription.subscriptionData) || {};

    const togglePortfolio = (portfolioId) => {
        setExpandedPortfolios(prev => ({
            ...prev,
            [portfolioId]: !prev[portfolioId],
        }));
    };
    console.log('csvData',csvData)
    const renderTable = (lineItems, portfolioId) => {
        const items = isHedgeableSelected ? lineItems?.supported : lineItems?.unsupported;
        if (!items || items.length === 0) {
            return <p>No {isHedgeableSelected ? 'Hedgeable' : 'Unhedgeable'} items available.</p>;
        }

        return (
            <>
                <table>
                    <thead>
                        <tr>
                            <th>Symbol</th>
                            <th>Quantity</th>
                            <th>Description</th>
                            <th>Close Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={`${portfolioId}-${index}`}>
                                <td>{item.symbol}</td>
                                <td>{item.quantity}</td>
                                <td>{item.Name || 'N/A'}</td>
                                <td>{item.ClosePrice || 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    };
    
    const renderPortfolios = () => {
        switch (csvData.csv_type) {
            case "single_client_schwabs":
                return Object.entries(csvData.portfolio).map(([portfolioId, { portfolio_name, line_items }]) => (
                    <div key={portfolioId} className="portfolio-section">
                        <div className="portfolio-header" onClick={() => togglePortfolio(portfolioId)}>
                            <ArrowForwardIosIcon className={`arrow-icon ${expandedPortfolios[portfolioId] ? 'expanded' : ''}`} />
                            <h2>{portfolio_name}</h2>
                        </div>
                        {expandedPortfolios[portfolioId] && renderTable(line_items)}
                    </div>
                ));
            case "multi_client_adaptive":
                return Object.entries(csvData).filter(([key]) => key !== "csv_type").flatMap(([clientId, clientData]) =>
                    Object.entries(clientData).filter(([key]) => key !== "client_name").map(([portfolioId, portfolioDetails]) => (
                        <div key={`${clientId}-${portfolioId}`} className="portfolio-section">
                            <div className="portfolio-header" onClick={() => togglePortfolio(`${clientId}-${portfolioId}`)}>
                                <h2>{portfolioDetails.portfolio_name}</h2>
                                <ArrowForwardIosIcon className={`arrow-icon ${expandedPortfolios[portfolioId] ? 'expanded' : ''}`} />
                            </div>
                            {expandedPortfolios[`${clientId}-${portfolioId}`] && renderTable(portfolioDetails.line_items)}
                        </div>
                    ))
                );
            case "single_client_ibkr":
            case "single_client_adaptive":
                return (
                    <>
                        {renderTable(csvData.line_items)}
                    </>
                );
            default:
                return <p>Unsupported CSV type.</p>;
        }
    };

    const csvFileBase64 = "U3ltYm9sLFF1YW50aXR5CkdPT0csOTAKTkZMWCwxMzAKTUVUQSwxMDAKQU1aTiwxMApUU0xBLDUwCkFBUEwsMTAw";

    function downloadCSVFile(e) {
        e.preventDefault();
        const element = document.createElement("a");
        const file = new Blob([atob(csvFileBase64)], { type: "text/csv;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = "SampleTemplate.csv";
        document.body.appendChild(element);
        element.click();
    }
    const Confirm = async () => {
        setIsLoading(true);
        let params = {};
        if (!openedFromSidebar) {
            if (csvData.csv_type === "single_client_schwabs") {
                params = { master_account: portfolioName };
            } else {
                    if (portfolioName === ''){
                        params = { customer_id: customerId};
                    } else{
                        params = { customer_id: customerId, portfolio_name: portfolioName };
                    }
            }
        }
        else {
            if (portfolioName != ''){
                params = { portfolio_name: portfolioName };
            }
        }
        console.log("prams", params)
        AddCsvData(params).then((response) => {
            if (response.status === 200) {
                dispatch(portfolioAdded());
                dispatch(closeDrawer());
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'Add Portfolio',
                        userType: sessionStorage.getItem("selectedAcType"),
                        user_id: sessionStorage.getItem("clientId"),
                        creation_method: 'CSV Import',
                    },
                });
            } else {
                handleApiError(response, dispatch);
            }
        }).finally(() => setIsLoading(false));
    };

    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileExt = file.name.split('.').pop();
            if (fileExt !== "csv") {
                setFileError('File format is not recognized. Please upload a valid CSV file');
                return;
            }
            handleFileRead(file);
            setSelectedFile(file);
            setFileError(null);
            // setImportStage(2);
        }
    };

    const handleFileRead = async (file) => {
        const reader = new FileReader();
        reader.onloadend = (onLoadEndEvent) => {
            // console.log(" upload file", onLoadEndEvent.target.result.split(",")[1])
            setIsLoading(true)
            setErrorMessage("")
            ProcessCsvFile(onLoadEndEvent.target.result.split(",")[1]).then((data) => {
                console.log("data", data)
                if(data.status === 200){
                    setCsvData(data.result)
                    setImportStage(2);
                }else{
                    handleApiError(data, dispatch);
                }
            }).finally(() => setIsLoading(false));
        };
        reader.readAsDataURL(file);

    };

    const onDrop = React.useCallback((event) => {
        event.preventDefault();
        if (event.dataTransfer.items) {
            let file = event.dataTransfer.items[0].getAsFile();
            var fileExt = file.name.split('.').pop();
            var fileExt = file.name.split('.').pop();
            console.log(" file", file, fileExt);
            var fileExt = file.name.split('.').pop();
            console.log(" file", file, fileExt);
            if (fileExt !== "csv") {
                setFileError('File format is not recognized. Please upload a valid CSV file');
                return;
            }
            handleFileRead(file);
            setSelectedFile(file);
            setFileError(null);
        }
    }, []);

    const handleImportBtn = (importStage) => {
        const limit = subscriptionsDetails.subscription_plan_active_portfolio_line_items_limit;
        if (csvData['subscription_limit_exceeded']) {
            const message = `Your current portfolio line items count (${csvData['line_items']['supported'].length}) exceeds the limit of ${limit}. Please upgrade your subscription to add more.`;
            dispatch(showNotification(message, 'info', 'toaster'));
            //setImportStage(1);
            return;
        }
        setImportStage(importStage);
    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
        <div className="drawer">
            {isLoading ? (
                <div className="loading-overlay">
                    <Loader />
                </div>) : (
                <>
                    <div className="drawer-header">
                        <button className="ai-btn mr16" onClick={() => dispatch(closeDrawer())}><img src="./Assets/home.svg"/>Dashboard</button>
                        <h1>Import New Portfolio</h1>
                        <div className="actions">
                        <button className="close-drawer" onClick={() => dispatch(closeDrawer())}>✕</button>
                        </div>
                    </div>
                    <>
                        <div class="tab center importportal">
                            <button className="impbutton">
                                <img className="" src='./Assets/Wave.svg'></img>
                                <p className="round_title_text">Import CSV</p>
                            </button>
                            <button className="impbutton" onClick={() => { setImportStage(2) }}>
                                <img className="" src='./Assets/BankConnect.svg'></img>
                                <p className="round_title_text">Verify Import</p>
                            </button>
                            <button className="impbutton">
                                <img className="" src='./Assets/Docs.svg'></img>
                                <p className="round_title_text">Complete</p>
                            </button>
                        </div>
                        {importStage === 1 &&
                            (
                                <div className="main-content">
                                    <button className="ai-btn line primary" onClick={(e) => downloadCSVFile(e)}>
                                        Download Sample CSV <img src='./Assets/Download.svg'></img>
                                    </button>
                                    <div
                                        className="upload-section"
                                        id="upload-section"
                                        onDrop={onDrop}
                                        onDragOver={(event) => event.preventDefault()}
                                    >
                                        <input
                                            type="file"
                                            id="file-upload"
                                            accept=".csv"
                                            onChange={onFileChange}
                                            style={{ display: 'none' }}
                                        />
                                        <div class="text-container">
                                            <label className="full-label" htmlFor="file-upload">
                                                <img src="./Assets/documents-file-excel.svg" alt="Click here to Upload Csv or Drag & Drop file here" />
                                                <p> Drag & Drop portfolio CSV here or click to upload.<br></br>
                                                    {selectedFile && <span className='c-secondary'>{selectedFile.name}</span>}
                                                </p>
                                            </label>
                                            {fileError && <p className="file-error" style={{color: 'red'}}>{fileError}</p>}
                                        </div>
                                    </div>
                                    {/* {errorMessage !== "" && <ErrorComponent message={errorMessage}> </ErrorComponent>} */}
                                </div>
                            )
                        }
                        {importStage === 2 &&
                            (
                                <div className="main-content">
                                    <div className="toogle-switch">
                                        <h2>Unhedgeable</h2>
                                        <FormControlLabel control={
                                            <Switch
                                                checked={isHedgeableSelected}
                                                onChange={() => setIsHedgeableSelected(!isHedgeableSelected)}
                                            />} />
                                        <h2>Hedgeable</h2>
                                    </div>
                                    {/* {isHedgeableSelected ? renderTable(csvData.hedgeable) : renderTable(csvData.unhedgeable)} */}
                                    {renderPortfolios()}
                                    <div className="button-div">
                                        <button className="ai-btn line grey" onClick={() => setImportStage(1)}>
                                            Back
                                        </button>
                                        <button className="ai-btn line primary" onClick={() => handleImportBtn(3)}>
                                            Import
                                        </button>
                                        <button className="ai-btn solid secondary" onClick={() => setImportStage(1)}>
                                            Exit
                                        </button>
                                    </div>
                                </div>
                            )}
                        {importStage === 3 &&
                            (
                                <div className="main-content">
                                    <label>
                                        {csvData.csv_type === "single_client_schwabs" ? "Master Account No" : "Portfolio Name (Optional)"}:
                                        <input
                                            type="text"
                                            placeholder={csvData.csv_type === "single_client_schwabs" ? "Master Account No" : "Portfolio Name (Optional)"}
                                            value={portfolioName}
                                            onChange={(e) => setPortfolioName(e.target.value)}
                                        />
                                    </label>
                                    <div className="button-div">
                                        <button className="ai-btn line grey" onClick={() => setImportStage(2)}>
                                            Back
                                        </button>
                                        <button className="ai-btn line primary" onClick={() => Confirm()}>
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            )}
                    </>
                </>
            )}
        </div>
        </>
    );
}

export default ImportCSVPortfolioDrawer;
