export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const showNotification = (message, alertType, popupType, redirectLink = '', loginRedirect = false) => ({
    type: SHOW_NOTIFICATION,
    payload: { message, alertType, popupType, redirectLink, loginRedirect }
});

export const showConfirmNotification = (message, confirmActionYes, confirmActionNo = null) => ({
    type: SHOW_NOTIFICATION,
    payload: { message, alertType: 'warning', popupType: 'confirm', confirmActionYes, confirmActionNo }
});

export const hideNotification = () => ({
    type: HIDE_NOTIFICATION
});

export const handleApiError = (response, dispatch) => {
    if (response.status === 401 || response.status === 404) {
        dispatch(showNotification("Session timeout, please login again.", "warning", "popup", '', true));
    } else if (response.status === 400 && response.error){
        dispatch(showNotification(response.error,"error","toaster"));
    } else {
        dispatch(showNotification("Something went wrong. Please try again", "error", "toaster"));
    }
};

export const pdfSummaryNotification = (message,atype) => {
    return (dispatch) => {
        dispatch(showNotification(message, atype, 'toaster'));
    };
}