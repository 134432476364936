import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import ADClientSummaryScreen from "./Screens/ADClientSummaryScreen/ADClientSummaryScreen";
import LoginScreen from "./Screens/LoginScreen/LoginScreen";
import OnboardClient from "./Screens/OnBoardClientPortfolioScreen/OnBoardClientPortfolioScreen";
import AdaptiveImportWizardScreen from "./Screens/ImportAdaptiveWizard/AdaptiveCSVImportWizardScreen";
import AdaptiveManualImportScreen from "./Screens/ImportAdaptiveWizard/AdaptiveManualImportWizardScreen";
import VerificationScreen from "./Screens/VerificationScreen/VerificationScreen";
import AdvisorProfileScreen from "./Screens/AdvisorProfileScreen/AdvisorProfileScreen";
import AdvisorModelPortfolioWizardScreen from './Screens/ImportAdaptiveWizard/AdaptiveModelPortfolioWizardScreen';
import ArchivePortfolioScreen from './Screens/ArchivePortfolioScreen/ArchivePortfolioScreen';
import { PersistGate } from 'redux-persist/integration/react';
// import OptionChainScreen from './Screens/OptionChainScreen/OptionChainScreen';
// import SelectionScreen from './Screens/SelectionScreen/SelectionScreen';

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

if (process.env.REACT_APP_SENTRY_ENVIRONMENT === "PRODUCTION") {
  TagManager.initialize(tagManagerArgs);
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              component={LoginScreen}
            />
            <Route
              exact
              path="/ClientPortfolios"
              component={ADClientSummaryScreen}
            />
            <Route
              exact
              path="/advisorsignup"
              render={(props) => <LoginScreen {...props} default_screen="Sign Up" user_type="Advisor" />}
            />
            <Route
              exact
              path="/investorsignup"
              render={(props) => <LoginScreen {...props} default_screen="Sign Up" user_type="Investor" />}
            />
            <Route
              exact
              path="/AdvisorProfileScreen"
              component={AdvisorProfileScreen}
            />
            <Route
              exact
              path="/OnboardScreen"
              component={OnboardClient}
            />
            <Route
              exact
              path="/AdaptiveImportWizard"
              component={AdaptiveImportWizardScreen}
            />
            <Route
              exact
              path="/AdaptiveManualImportWizard"
              component={AdaptiveManualImportScreen}
            />
            <Route
              exact
              path="/VerificationScreen"
              component={VerificationScreen}
            />
            <Route
              exact
              path="/AdaptiveModelPortfolioWizard"
              component={AdvisorModelPortfolioWizardScreen}
            />
            <Route
              exact
              path="/ArchivePortfolio"
              component={ArchivePortfolioScreen}
            />
            {/* <Route path="/option-chain" element={<OptionChainScreen />} />
            <Route path="/option-select" element={<SelectionScreen />} /> */}
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
