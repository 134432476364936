import React, { useState } from "react";
import './OnboardClient.scss';
import { useHistory, useLocation } from 'react-router-dom';
import UserPool from "../../UserPool";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function OnboardClient() {

    const history = useHistory();
    const location = useLocation();
    const handleLogOut = () => history.push("/");

    const initialStage = location.state && location.state.stage ? location.state.stage : 1;
    const [onboardStage, setOnboardStage] = React.useState(initialStage);
    const PaymentLinks = JSON.parse(sessionStorage.getItem("PaymentLinks"));
    const userType = sessionStorage.getItem("userType");

    const redirectToPayment = (plan_name) => {
        const CustomerID = sessionStorage.getItem("CustomerID");
        if (CustomerID && PaymentLinks) {
            if(userType === 'Investor' && plan_name === 'Investor'){
                const url = new URL(PaymentLinks.investor);
                url.searchParams.set('client_reference_id', CustomerID);
                window.location.href = url.toString();
            }
            else if(userType === 'Advisor' && plan_name === 'Advisor'){
                const url = new URL(PaymentLinks.standard);
                url.searchParams.set('client_reference_id', CustomerID);
                window.location.href = url.toString();
            } else if(userType === 'Advisor' && plan_name === 'Advisor Pro'){
                const url = new URL(PaymentLinks.pro);
                url.searchParams.set('client_reference_id', CustomerID);
                window.location.href = url.toString();
            }

            
        } else {
            console.error("CustomerID or PaymentLinks.standard is missing.");
        }
    };

    function handleExit() {
        const user = UserPool.getCurrentUser();
        if (user) {
            user.signOut();
            window.sessionStorage.clear();
            window.localStorage.clear();
            handleLogOut();
        }
    }

    return (
        <>
            <h1 className="boardlogo"><img src="./Assets/logo.svg" /></h1>
            <div className="onboarding">
                <div className={onboardStage === 1 ? 'onboardstep active' : 'onboardstep'}>
                    <div className="icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.1871 2.34668L21.7471 3.78668" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.0801 2.34668L12.4134 3.68001" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.1335 1.33325V3.21325" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.4424 30.0599C22.2544 30.3986 25.1864 29.4973 27.3437 27.3386V27.3386C29.6024 25.0799 30.5824 21.8413 29.9571 18.7093L28.3011 10.4319C28.1077 9.46393 27.2571 8.7666 26.2691 8.7666V8.7666C25.1251 8.7666 24.1971 9.6946 24.1971 10.8386V12.8159" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.4933 10.7187L18.0693 8.29474C17.2879 7.51341 16.0213 7.51341 15.2413 8.29474V8.29474C14.4599 9.07607 14.4599 10.3427 15.2413 11.1227" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.04245 10.5827C8.26111 9.80137 8.26111 8.5347 9.04245 7.7547V7.7547C9.82378 6.97337 11.0904 6.97337 11.8704 7.7547L17.0558 12.94L19.8838 15.768V12.1827C19.8838 11.0387 20.8118 10.1107 21.9558 10.1107V10.1107C22.9438 10.1107 23.7944 10.808 23.9878 11.776L25.4798 19.2387C26.1064 22.3707 25.1264 25.6094 22.8664 27.868V27.868C19.1358 31.5987 13.0864 31.5987 9.35578 27.868L5.74245 24.2534L2.44245 20.9534C1.66111 20.172 1.66111 18.9054 2.44245 18.1254V18.1254C3.22378 17.344 4.49045 17.344 5.27045 18.1254" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.51478 22.3679L3.38678 16.2399C2.60545 15.4586 2.60545 14.1919 3.38678 13.4119V13.4119C4.16811 12.6306 5.43478 12.6306 6.21478 13.4119" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.3425 19.5387L4.32916 11.5253C3.54783 10.744 3.54783 9.47733 4.32916 8.69733V8.69733C5.1105 7.91599 6.37716 7.91599 7.15716 8.69733L15.1705 16.7107" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <p className="">Welcome</p>
                </div>
                <div className={onboardStage === 2 ? 'onboardstep active' : 'onboardstep'}>
                    <div className="icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.33366 21.3334V9.77344" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M26.6667 9.77344V21.3334" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M29.3337 11.5561L16.0003 2.66675L2.66699 11.5561" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.6667 13.3333V18.6666" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.9997 13.3333V18.6666" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21.3337 13.3333V18.6666" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 24L14.6667 26.6667L12 29.3333" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.6667 26.6667H4" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M28 26.6667H20" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <p className="">Select Plan</p>
                </div>
                <div className={onboardStage === 3 ? 'onboardstep active' : 'onboardstep'}>
                    <div className="icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.6797 22.6667H21.3197" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.6797 17.3334H21.3197" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3333 12H16.7133C16.268 12 15.8507 11.7773 15.604 11.4067L13.7293 8.59467C13.4813 8.22267 13.0653 8 12.62 8H6.66667C5.19333 8 4 9.19333 4 10.6667V25.3333C4 26.8067 5.19333 28 6.66667 28H25.3333C26.8067 28 28 26.8067 28 25.3333V14.6667C28 13.1933 26.8067 12 25.3333 12Z" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.66699 8V6.66667C6.66699 5.19333 7.86033 4 9.33366 4H22.667C24.1403 4 25.3337 5.19333 25.3337 6.66667V12" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <p className="">Add Portfolio</p>
                </div>
                <div className="onboardstep">
                    <div className="icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6667 28.0001H6.66667C5.19333 28.0001 4 26.8067 4 25.3334V13.3334C4 11.8601 5.19333 10.6667 6.66667 10.6667H18.6667C20.14 10.6667 21.3333 11.8601 21.3333 13.3334V25.3334C21.3333 26.8067 20.14 28.0001 18.6667 28.0001Z" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.5 19.8785L11.612 21.9905L16.2213 17.3799" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.33301 10.6667V6.66667C9.33301 5.19333 10.5263 4 11.9997 4H25.333C26.8063 4 27.9997 5.19333 27.9997 6.66667V20C27.9997 21.4733 26.8063 22.6667 25.333 22.6667H21.333" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <p className="">Complete</p>
                </div>
            </div>
            {onboardStage === 1 &&
                (
                    <div className="welcome">
                        <div className="welcomecon">
                            <img className="" src='./Assets/Wave-1.svg'></img>
                            <h1>Welcome to Adaptive</h1>
                            <p>We will help you to setup your account</p>
                        </div>
                        <div className="button-div">
                            <button className="ai-btn line primary" onClick={() => setOnboardStage(2)}>
                                Next step: Select Plan <ArrowForwardIosIcon />
                            </button>
                        </div>
                    </div>
                )
            }
            {onboardStage === 2 &&
                (
                    <div className="welcome">
                        <div className="welcomecon">
                            <h1>
                                Select Plan
                            </h1>
                        </div>
                        {userType === 'Advisor' && (
                            <div className="onboardcards">
                                <div className="plan standard">
                                    <div className="head">
                                        <div className="icon">
                                            <img src='./Assets/Standard_Seat.svg'></img>
                                        </div>
                                        <div className="planname">
                                            <h3>Advisor</h3>
                                            <p className="price">$65 Per User/ Per Month (30-day free Trial)</p>
                                        </div>
                                    </div>
                                    <div className="feature">
                                        <ul className="">
                                            <li><img className="" src='./Assets/checklist.svg'></img>Up to 10 Portfolios of miximum 100 Tickers. Unlimited if Linked to Brokerage Platforms.</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>Market Quotes (Prev Close)</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>User Upload & Update of Portfolio Positions</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>User Download of Possible Options Trades</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>Call Writing Optimization During Trial Period</li>
                                        </ul>
                                    </div>
                                    <button className="ai-btn solid secondary" onClick={() => redirectToPayment('Advisor')}>
                                        Start free trial <KeyboardDoubleArrowRightIcon />
                                    </button>
                                </div>
                                <div className="plan advisor">
                                    <div className="head">
                                        <div className="icon">
                                            <img src='./Assets/Advisor_Pro.svg'></img>
                                        </div>
                                        <div className="planname">
                                            <h3>Advisor Pro</h3>
                                            <p className="price">$500 (30-day free Trial)</p>
                                        </div>
                                    </div>
                                    <div className="feature">
                                        <ul className="">
                                            <li><img className="" src='./Assets/checklist.svg'></img>Unlimited Portfolios and Tickers.</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>Market Quotes (Real Time)</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>Advisor Platform Integrations for Portfolio Positions</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>Advisor Platform Integrations for Trade Tickets</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>Call Writing Optimization</li>
                                        </ul>
                                    </div>
                                    <button className="ai-btn solid secondary" onClick={() => redirectToPayment('Advisor Pro')}>
                                        Start free trial <KeyboardDoubleArrowRightIcon />
                                    </button>
                                </div>
                            </div>
                        )}
                        {userType === 'Investor' && (
                            <div className="onboardcards">
                                <div className="plan standard">
                                    <div className="head">
                                        <div className="icon">
                                            <img src='./Assets/Standard_Seat.svg'></img>
                                        </div>
                                        <div className="planname">
                                            <h3>Investor</h3>
                                            <p className="price">$25 Per User/ Per Month (30-day free Trial)</p>
                                        </div>
                                    </div>
                                    <div className="feature">
                                        <ul className="">
                                            <li><img className="" src='./Assets/checklist.svg'></img>Up to 3 Portfolios of maximum 50 Tickers.</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>Market Quotes (Prev Close)</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>User Upload & Update of Portfolio Positions</li>
                                            <li><img className="" src='./Assets/checklist.svg'></img>User Download of Possible Options Trades</li>
                                            <li><img className="" src='./Assets/notavil.svg'></img>No Call Writing Optimization</li>
                                        </ul>
                                    </div>
                                    <button className="ai-btn solid secondary" onClick={() => redirectToPayment('Investor')}>
                                        Start free trial <KeyboardDoubleArrowRightIcon />
                                    </button>
                                </div>
                            </div>
                        )}
                        <div className="button-div">
                            <button className="ai-btn line secondary" onClick={() => handleExit()}>
                                Exit
                            </button>
                        </div>
                    </div>
                )
            }
            {onboardStage === 3 &&
                (
                    <div className="welcome">
                        <div className="welcomecon">
                            <h1>
                                Add Portfolio
                            </h1>
                            <div className="onboardcards">
                                {/* <a href="/AdaptiveImportWizard">
                                    <div className="icon">
                                        <img className="product_card_img" src='./Assets/CSV.svg'></img>
                                    </div>
                                    <p className="title_text">Import from CSV</p>
                                </a> */}
                                <a href="/AdaptiveManualImportWizard">
                                    <div className="icon">
                                        <img className="product_card_img" src='./Assets/Typing.svg'></img>
                                    </div>
                                    <p className="title_text">Enter Manually</p>
                                </a>
                                <a href="/AdaptiveModelPortfolioWizard">
                                    <div className="icon">
                                        <img className="product_card_img" src='./Assets/Typing.svg'></img>
                                    </div>
                                    <p className="title_text">Model Portfolio</p>
                                </a>
                            </div>
                        </div>
                        {/* <div className="button-div">
                            <button className="ai-btn line grey" onClick={() => setOnboardStage(1)}>
                                <ArrowBackIosNewIcon /> Previous
                            </button> */}
                            {/* <button className="ai-btn line primary" onClick={() => setOnboardStage(3)}>
                                Next <ArrowForwardIosIcon/>
                            </button> */}
                            {/* <button className="ai-btn line secondary" onClick={() => setOnboardStage(1)}>
                                Skip <KeyboardDoubleArrowRightIcon/>
                            </button> */}
                        {/* </div> */}
                    </div>
                )}
        </>
    );
}

export default OnboardClient;
