import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import PortfolioDetails from '../TableComponent/PortfolioDetails';
import "./ArchivePortfolio.scss";
import '../TableComponent/CustomerPortfolio.scss';
import { fetchPortfolio, togglePortfolioSelection, toggleClientSelection, selectPortfolio, selectClient } from '../../actions/portfolioActions';
import { fetchPortfolioDetailsIfNeeded } from '../../actions/portfolioDetailsActions';
import { addZeroes } from "../../Utilities/AddZeros";
import {
    handleApiError,
    showConfirmNotification,
    showNotification,
  } from "../../actions/notificationActions";
import { PostArchivePortfolioApi } from '../../Apis/PostArchivePortfolio';
import Toaster from "../../Components/Toaster/Toaster";
import TagManager from "react-gtm-module";
import { updateUserSubscriptionData } from '../../actions/userSubscriptionActions';


function ArchivedPortfolio() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { customers, selectedPortfolioIds,isLoading, selectedClientIds } = useSelector(state => state.portfolio);

    const subscriptionsDetails = useSelector(state => state.userSubscription.subscriptionData) || {};
    const [openPortfolios, setOpenPortfolios] = useState({});
    const [globalLoading, setGlobalLoading] = useState(true);

    const [portfolioArchivedMap, setPortfolioArchivedMap] = useState({});
    const [portfolioHedgeableMap, setPortfolioHedgeableMap] = useState({});
    const [SchwabAccountPortfoliosMap, setSchwabAccountPortfoliosMap] = useState({});
    const navigate = (path) => {
        history.push(path);
    };

    useEffect(() => {
        dispatch(fetchPortfolio()).finally(() => setGlobalLoading(false));
    }, [dispatch]);

    const { archivedMap, hedgeableMap, schwabPortfolioMap } = useMemo(() => {
        const archivedMap = {};
        const hedgeableMap = {};
        const schwabPortfolioMap = {};
        customers.forEach(customer => {
            customer.Portfolio.forEach(portfolio => {
                if (portfolio.HasSchwabAccountId) {
                    schwabPortfolioMap[portfolio.PortfolioId] = !portfolio.HasSchwabAccountId;
                } else {
                    archivedMap[portfolio.PortfolioId] = !portfolio.PortfolioArchived;
                }
                hedgeableMap[portfolio.PortfolioId] = portfolio.Hedgable;
            });
        });

        return { archivedMap, hedgeableMap, schwabPortfolioMap };
    }, [customers]);

    useEffect(() => {
        setPortfolioArchivedMap(archivedMap);
        setPortfolioHedgeableMap(hedgeableMap);
        setSchwabAccountPortfoliosMap(schwabPortfolioMap);
    }, [archivedMap, hedgeableMap,schwabPortfolioMap]);
    
    if (subscriptionsDetails.archived_status || subscriptionsDetails.user_type !== 'Advisor' || ['Adaptive Advisor Pro Plan', 'Whitelist Customer Plan'].includes(subscriptionsDetails.subscription_plan_type)) {
        return <Redirect to={{ pathname: "/ClientPortfolios" }} />;
    } else if (subscriptionsDetails.subscription_status !== 'active' && subscriptionsDetails.subscription_plan_type === 'No Active Plan') {
        return <Redirect to={{ pathname: "/OnboardScreen", state: { stage: 2 } }} />;
    }

    const handleCheckboxChange = (portfolioId) => {
        if (portfolioHedgeableMap[portfolioId] > subscriptionsDetails.subscription_plan_active_portfolio_line_items_limit) {
            const message = (
                <>
                    You have exceeded the Standard plan limits. Each portfolio is allowed up to a maximum of {subscriptionsDetails.subscription_plan_active_portfolio_line_items_limit} tickers. 
                    Please adjust your selections or consider upgrading to 
                    <a onClick={()=>navigate("/AdvisorProfileScreen")} rel="noopener noreferrer"> <strong>Pro Plan</strong></a> for unlimited access.
                </>
            );            
            dispatch(showNotification(message, "info", "toaster"));
        } else {
            const falseCount = Object.values(portfolioArchivedMap).filter(value => value === false).length;
            if (falseCount >= subscriptionsDetails.subscription_plan_active_portfolio_limit && portfolioArchivedMap[portfolioId] !== false) {
                const message = (
                    <>
                        You have exceeded the Standard plan limits. Each user is allowed up to {subscriptionsDetails.subscription_plan_active_portfolio_limit} active portfolios, each with a maximum of {subscriptionsDetails.subscription_plan_active_portfolio_line_items_limit} tickers. Please adjust your selections or consider upgrading to our <a onClick={()=>navigate("/AdvisorProfileScreen")} rel="noopener noreferrer"> <strong>Pro Plan</strong></a> for unlimited access.
                    </>
                );   
                dispatch(showNotification(message, "info", "toaster"));
            } else {
                setPortfolioArchivedMap(prevMap => ({
                    ...prevMap,
                    [portfolioId]: portfolioId in prevMap ? !prevMap[portfolioId] : false,
                }));
            }
        }
    };
    
    const confirmArchivePortfolio = () =>{
        dispatch(
            showConfirmNotification(
              `All non-selected portfolios will be archived for 30 days, After 30 days, all archived (non-selected) portfolios will be permanently removed from your access. Do you wish to continue?`,
              () => SubmitArchivePortfolios()
            )
          );
    }

    const SubmitArchivePortfolios = () => {
        setGlobalLoading(true);
        const nonArchivedPortfolios = Object.keys(portfolioArchivedMap).filter(portfolioId => portfolioArchivedMap[portfolioId] === false);
        if (nonArchivedPortfolios.length > 10) {
            const message = `You can only have up to 10 portfolios. Please adjust your selection.`;
            dispatch(showNotification(message, "info", "toaster"));
            setGlobalLoading(false);
            return;
        }

        let exceedsLimit = false;
        for (const portfolioId of nonArchivedPortfolios) {
            if (portfolioHedgeableMap[portfolioId] > 100) {
                exceedsLimit = true;
                break;
            }
        }
        if (exceedsLimit) {
            const message = `One or more selected portfolios exceed the limit of 100 hedgeable items. Please adjust your selections.`;
            dispatch(showNotification(message, "info", "toaster"));
            setGlobalLoading(false);
        } else {
            PostArchivePortfolioApi({ ...SchwabAccountPortfoliosMap,...portfolioArchivedMap}).then(async(response) => {
                if (response.status === 200) {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "Portfolio Archived",
                            userType: sessionStorage.getItem("selectedAcType"),
                            user_id: sessionStorage.getItem("clientId"),
                        },
                    });
                    dispatch(showNotification("Portfolios Archived Successfully", "success", "toaster"));
                    setGlobalLoading(false);
                    await dispatch(updateUserSubscriptionData({archived_status: true}));
                    history.push({ pathname: "/ClientPortfolios" });
                } else {
                    setGlobalLoading(false);
                    handleApiError(response, dispatch);

                }
            });
        }
    };

    var currObj = {
        style: "currency",
        currency: "USD",
    };

    const toggleTable = (portfolioId) => {
        setOpenPortfolios(prevOpenPortfolios => ({
            ...prevOpenPortfolios,
            [portfolioId]: !prevOpenPortfolios[portfolioId]
        }));
    };

    const handlePortfolioClick = (portfolioId,action,CustomerId) => {
        dispatch(togglePortfolioSelection(portfolioId,action));
        dispatch(fetchPortfolioDetailsIfNeeded(portfolioId));
        toggleTable(portfolioId);
        //dispatch(selectPortfolio(portfolioId,action));
        dispatch(selectClient(CustomerId));
    };

    const handleClientClick = (CustomerId) => {
        dispatch(toggleClientSelection(CustomerId,'toggle'));
        dispatch(selectClient(CustomerId));
        dispatch(selectPortfolio(null));
        //toggleClients(CustomerId);
    };

    return (
        <>
        {globalLoading ? (
            <div className="global-loading-overlay">
                <Loader/>
            </div>
        ) : (
            <div className="tb-container">
            <div className="toast-container">
                <Toaster />
            </div>
            <div className="toastwarn">
                <p>Please Select Up to {subscriptionsDetails.subscription_plan_active_portfolio_limit} Active Portfolios (Maximum of {subscriptionsDetails.subscription_plan_active_portfolio_line_items_limit} Tickers Each) or Consider Upgrading to <a onClick={()=>navigate("/AdvisorProfileScreen")}><strong>Pro Plan</strong></a>.</p>
                <button
                    id="archivePortfolio"
                    className="ai-btn primary solid"
                    onClick={confirmArchivePortfolio}
                    style={{ marginLeft: '10px' }}
                >
                    Mark as Active
                </button>
            </div>

            <div className="customer-portfolio">
                {customers.map((customer, index) => (
                    <div id ={customer.CustomerId} key={customer.CustomerId} className={selectedClientIds.includes(customer.CustomerId) ? 'customer active' : 'customer'}>
                        <div className='portfoliohead'>
                            <span className='icon' onClick={() =>handleClientClick(customer.CustomerId)}>
                                {selectedClientIds.includes(customer.CustomerId) ? <img src="Assets/c_up.svg" alt="toggle" /> : <img src="Assets/c_down.svg" alt="toggle" />}
                            </span>
                            <h2>{customer.FirstName} {customer.LastName}</h2>
                        </div>
                        {selectedClientIds.includes(customer.CustomerId) && (
                            <div className={selectedClientIds.includes(customer.CustomerId) ? 'portfolios' : 'portfolios'}>
                                {customer.Portfolio.map((portfolio) => (
                                    <>
                                        {selectedClientIds.includes(customer.CustomerId) && (
                                            <div id={portfolio.PortfolioId} className={openPortfolios[portfolio.PortfolioId] ? 'portfoliocard' : 'portfoliocard'} key={portfolio.PortfolioId}>
                                                <div className={openPortfolios[portfolio.PortfolioId] ? 'portlist active' : 'portlist'}>

                                                    <div className={openPortfolios[portfolio.PortfolioId] ? 'listhead' : 'listhead'}>
                                                        {!portfolio.HasSchwabAccountId && (
                                                            <><input 
                                                                type='checkbox'
                                                                checked={!portfolioArchivedMap[portfolio.PortfolioId]}
                                                                onChange={() => handleCheckboxChange(portfolio.PortfolioId)}
                                                            />&nbsp;</>
                                                        )}
                                                        <h3 title={portfolio.PortfolioName}>
                                                            {portfolio.PortfolioName}
                                                        </h3>
                                                        <h3 className="portfolio-stocks">
                                                            HOLDINGS:{" "}
                                                            {portfolio.hFlag?portfolio.Unhedgable:portfolio.Hedgable}
        
                                                        </h3>
                                                        <h3 className="portfolio-value">
                                                            PORTFOLIO VALUE:{" "}
                                                            {Number(
                                                            addZeroes(
                                                                Number(
                                                                parseFloat(
                                                                    portfolio.TotalPortfolioValue ?? "0"
                                                                ).toFixed(2)
                                                                )
                                                            )
                                                            ).toLocaleString("en-US", currObj)}
                                                        </h3>
                                                        <button className='icon' onClick={() => handlePortfolioClick(portfolio.PortfolioId,'toggle',customer.CustomerId)}>
                                                            {selectedPortfolioIds.includes(portfolio.PortfolioId) ? <img src="Assets/c_up.svg" alt="toggle" /> : <img src="Assets/c_down.svg" alt="toggle" />}
                                                        </button>
                                                    </div>
                                                    <div className='clientdata'>
                                                        {selectedPortfolioIds.includes(portfolio.PortfolioId) && <PortfolioDetails portfolioId={portfolio.PortfolioId} portfolioName={portfolio.PortfolioName} hasSchwabAccount={portfolio.HasSchwabAccountId} portfolio={portfolio} customer={customer} allCustomers={customers} hedgeableFlag={portfolio.hFlag} archiveStatusFlag = {subscriptionsDetails.archived_status}/>}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}

                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
        )}
        </>
        
    );
}

export default ArchivedPortfolio;
