import React, { useState, useEffect, useMemo, useCallback, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import './AutoCompleteTicker.scss';
import { Autocomplete, Box, TextField, Typography, CircularProgress } from '@mui/material';
import debounce from 'lodash.debounce';
import { FixedSizeList } from 'react-window';
import { addZeroes } from "../../Utilities/AddZeros";

const ListboxComponent = React.memo(forwardRef(function ListboxComponent(props, ref) {
  const { children, loading, ...otherProps } = props;
  const itemCount = React.Children.count(children) + (loading ? 1 : 0);

  return (
    <div ref={ref} {...otherProps}>
      <FixedSizeList
        height={400}
        width={400}
        itemSize={40}
        itemCount={itemCount}
        overscanCount={10}
      >
        {({ index, style }) => {
          if (loading && index === 0) {
            return (
              <div style={style} key="loading">
                <Box display="flex" alignItems="center" justifyContent="center" height="40px">
                  <CircularProgress size={20} />
                </Box>
              </div>
            );
          }
          const childIndex = loading ? index - 1 : index;
          if (childIndex < 0 || childIndex >= React.Children.count(children)) {
            return null;
          }
          const child = React.Children.toArray(children)[childIndex];
          return (
            <div style={style} key={child.key}>
              {child}
            </div>
          );
        }}
      </FixedSizeList>
    </div>
  );
}));

const AutoCompleteTicker = React.memo(({ onTickerChange }) => {
  const availableTickers = useSelector(state => state.availableTickers?.availableTickers || []);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue.toUpperCase());
  };

  const cache = useMemo(() => new Map(), []);

  const loadOptions = useCallback(async (query) => {
    if (cache.has(query)) {
      setOptions(cache.get(query));
      setLoadingOptions(false);
      return;
    }
    setLoadingOptions(true);
    try {
      const filteredTickers = availableTickers.filter(ticker =>
        ticker.symbol.toLowerCase().includes(query.toLowerCase())
      );
      cache.set(query, filteredTickers);
      setOptions(filteredTickers);
    } catch (error) {
      setOptions([]);
    } finally {
      setLoadingOptions(false);
    }
  }, [cache, availableTickers]);

  const debouncedLoadOptions = useMemo(() => debounce(loadOptions, 200), [loadOptions]);

  const renderOption = (props, option) => {
    const { key, ...otherProps } = props;
    return (
      <li key={key} {...otherProps}>
        <Typography variant="body2">
          <strong>{option.symbol}</strong> - {option.company_name}  - {Number(addZeroes(Number(parseFloat(option.buyprice).toFixed(2)))).toLocaleString('US',currObj)}
        </Typography>
      </li>
    );
  };
  

  const filteredOptions = useMemo(() => {
    if (!inputValue) return options;
    return options
      .filter(option => option.symbol.toLowerCase().startsWith(inputValue.toLowerCase()))
      .sort((a, b) => a.symbol.localeCompare(b.symbol));
  }, [inputValue, options]);

  useEffect(() => {
    if (inputValue) {
      debouncedLoadOptions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue, debouncedLoadOptions]);

  const handleChange = (event, value) => {
    if (typeof value === 'string') {
      onTickerChange(null,{ symbol: value });
    } else if (value && value.symbol) {
      onTickerChange(null,value);
    }
  };
  
  const handleBlur = () => {
    if (inputValue) {
      onTickerChange(null, { symbol: inputValue });
    }
  };
  
  return (
    <div>
      <Autocomplete
        freeSolo
        className='ai-input'
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onBlur={handleBlur}
        options={filteredOptions || []}
        getOptionLabel={(option) => option.symbol || ''}
        style={{ width: '400px' }}
        ListboxComponent={ListboxComponent}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={renderOption}
      />
    </div>
  );
});

export default AutoCompleteTicker;
